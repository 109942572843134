export const isValidEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.trim());
}

export function validateYear(year) {
    const minYear = 1;
    const maxYear = new Date().getFullYear()

    if (Number(year) && Number(year) !== NaN) {
        if (Number(year) <= maxYear && Number(year) >= minYear) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function isValidUrl(string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
}

export function isValidTwitter(string) {
    var res = string.match(/^@?(\w){1,32}$/g);
    return (res !== null)
};