import { types, status } from '../constants/action-types';
import { errorToString } from '../utils/error'

const defaultComments = {
	reviews: {},
    lists: {},
    ratings: {},
    threads: {},

    lastCreatedCommentIsReply: false,
    lastCreatedCommentRootUid: null,
    createCommentStatus: status.DEFAULT,
    
    lastUpdatedCommentUid: null,
    updateCommentStatus: status.DEFAULT,

    lastDeletedCommentUid: null,
    deleteCommentStatus: status.DEFAULT,

    errorMessage: null,
    
};

const comments = (state = defaultComments, action) => {
    let uid = action.meta && action.meta.params ? action.meta.params.uid : null
    let reset = action.meta && action.meta.params ? action.meta.params.reset : null
    let pluralType = action.meta && action.meta.params ? action.meta.params.pluralType : null
    let commentUid = action.meta && action.meta.params ? action.meta.params.commentUid : null
    let rootCommentUid = action.meta && action.meta.params ? action.meta.params.rootCommentUid : null

    let rootCommentContentType = action.meta && action.meta.params ? `${action.meta.params.rootCommentContentType}s` : null
    let rootCommentContentUid = action.meta && action.meta.params ? action.meta.params.rootCommentContentUid : null

	switch (action.type) {
		case types.GET_COMMENTS:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						[pluralType]: {
                            ...state[pluralType],
                            [uid]: {
                                data: state[pluralType][uid] && state[pluralType][uid].data ? state[pluralType][uid].data : [],
                                count: state[pluralType][uid] && state[pluralType][uid].count ? state[pluralType][uid].count : 0,
                                next: null,
                                reset: action.meta && action.meta.params ? action.meta.params.reset : false,
							    status: status.BEGIN,
                            }
						},
					}
				case status.SUCCESS:
					return {
						...state,
						[pluralType]: {
                            ...state[pluralType],
                            [uid]: {
                                data: reset ? action.payload.results : [ ...state[pluralType][uid].data, ...action.payload.results ],
                                count: action.payload.count,
                                next: action.payload.next,
							    status: status.SUCCESS,
                            }
                        },
                        errorMessage: null,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						[pluralType]: {
                            ...state[pluralType],
                            [uid]: {
                                data: [],
                                count: 0,
                                next: null,
							    status: status.ERROR,
                            }
						},
					}
			}

		case types.CREATE_COMMENT:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
                        createCommentStatus: status.BEGIN,
                        lastCreatedCommentIsReply: commentUid ? true : false,
                        lastCreatedCommentRootUid: commentUid
					}
				case status.SUCCESS:
                    return (
                        //If the comment is NOT a reply
                        !commentUid ? {
                            ...state,
                            [pluralType]: {
                                ...state[pluralType],
                                [uid]: {
                                    ...state[pluralType][uid],
                                    data: [
                                        action.payload,
                                        ...state[pluralType][uid].data,
                                    ]
                                }
                            },
                            createCommentStatus: status.SUCCESS,
                            errorMessage: null,
                        }
                        //If the comment is a reply
                        : {
                            ...state,
                            [pluralType]: {
                                ...state[pluralType],
                                [uid]: {
                                    ...state[pluralType][uid],
                                    data: state[pluralType][uid].data.map((comment) => {
                                        if(comment.uid !== commentUid) return comment
    
                                        return ({
                                            ...comment,
                                            replies: {
                                                ...comment.replies,
                                                count: comment.replies && comment.replies.count ? comment.replies.count + 1 : 1,
                                                results: 
                                                comment.replies && comment.replies.results ? 
                                                    [
                                                        action.payload,
                                                        ...comment.replies.results,
                                                    ]
                                                :
                                                    [ action.payload ]
                                            }
                                        })
                                    }),
                                }
                            },
                            createCommentStatus: status.SUCCESS,
                            errorMessage: null,
                        }
                    )
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						createCommentStatus: status.ERROR,
					}
            }
            
		case types.UPDATE_COMMENT:

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
                        updateCommentStatus: status.BEGIN,
                        lastUpdatedCommentUid: commentUid,
					}
				case status.SUCCESS:
                    return (
                        //If the comment is NOT a reply
                        !rootCommentUid ? {
                            ...state,
                            [pluralType]: {
                                ...state[pluralType],
                                [uid]: {
                                    ...state[pluralType][uid],
                                    data: state[pluralType][uid].data.map((comment) => {
                                        if(comment.uid !== commentUid) return comment
    
                                        return action.payload
                                    }),
                                }
                            },
                            updateCommentStatus: status.SUCCESS,
                            errorMessage: null,
                        }
                        //If the comment is a reply
                        : {
                            ...state,
                            [pluralType]: {
                                ...state[pluralType],
                                [uid]: {
                                    ...state[pluralType][uid],
                                    data: state[pluralType][uid].data.map((comment) => {
                                        if(comment.uid !== rootCommentUid) return comment
    
                                        return ({
                                            ...comment,
                                            replies: {
                                                ...comment.replies,
                                                results: comment.replies.results.map((comment) => {
                                                    if(comment.uid !== commentUid) return comment
                
                                                    return action.payload
                                                }),
                                            }
                                        })
                                    }),
                                }
                            },
                            updateCommentStatus: status.SUCCESS,
                            errorMessage: null,
                        }
                    )
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						updateCommentStatus: status.ERROR,
					}
			}
            
		case types.DELETE_COMMENT:

			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
                        deleteCommentStatus: status.BEGIN,
                        lastDeletedCommentUid: commentUid,
					}
				case status.SUCCESS:
                    return (
                        //If the comment is NOT a reply
                        !rootCommentUid ? {
                            ...state,
                            [pluralType]: {
                                ...state[pluralType],
                                [uid]: {
                                    ...state[pluralType][uid],
                                    data: state[pluralType][uid].data.filter((comment) => comment.uid !== commentUid),
                                }
                            },
                            deleteCommentStatus: status.SUCCESS,
                            errorMessage: null,
                        }
                        //If the comment is a reply
                        : {
                            ...state,
                            [pluralType]: {
                                ...state[pluralType],
                                [uid]: {
                                    ...state[pluralType][uid],
                                    data: state[pluralType][uid].data.map((comment) => {
                                        if(comment.uid !== rootCommentUid) return comment
    
                                        return ({
                                            ...comment,
                                            replies: {
                                                ...comment.replies,
                                                results: comment.replies.results.filter((comment) => comment.uid !== commentUid)
                                            }
                                        })
                                    }),
                                }
                            },
                            deleteCommentStatus: status.SUCCESS,
                            errorMessage: null,
                        }
                    )
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						deleteCommentStatus: status.ERROR,
					}
			}

		case types.GET_MORE_REPLIES:
			switch (action.meta.status) {
				case status.BEGIN:
					return {
						...state,
						[pluralType]: {
							...state[pluralType],
							[uid]: {
								...state[pluralType][uid],
								data: state[pluralType][uid].data.map((comment) => {
                                    if(comment.uid !== commentUid) return comment

                                    return ({
                                        ...comment,
                                        replies: {
                                            ...comment.replies,
                                            next: null,
                                            reset: action.meta && action.meta.params ? action.meta.params.reset : false,
                                            status: status.BEGIN,
                                        }
                                    })
                                }),
							}
						},
					}
				case status.SUCCESS:
					return {
						...state,
						[pluralType]: {
							...state[pluralType],
							[uid]: {
								...state[pluralType][uid],
								data: state[pluralType][uid].data.map((comment) => {
                                    if(comment.uid !== commentUid) return comment

                                    return ({
                                        ...comment,
                                        replies: {
                                            ...comment.replies,
                                            status: status.SUCCESS,
                                            next: action.payload.results[action.payload.results.findIndex((comment) => comment.uid === commentUid)].replies.next,
                                            count: action.payload.results[action.payload.results.findIndex((comment) => comment.uid === commentUid)].replies.count,
                                            results: [ 
                                                ...comment.replies.results, 
                                                ...action.payload.results[action.payload.results.findIndex((comment) => comment.uid === commentUid)].replies.results
                                            ]
                                        }
                                    })
                                }),
							}
                        },
                        errorMessage: null,
					}
				case status.ERROR:
					return {
						...state,
						errorMessage: errorToString(action.payload),
						[pluralType]: {
							...state[pluralType],
							[uid]: {
								...state[pluralType][uid],
								data: state[pluralType][uid].data.map((comment) => {
                                    if(comment.uid !== commentUid) return comment

                                    return ({
                                        ...comment,
                                        replies: {
                                            ...comment.replies,
                                            next: null,
                                            status: status.ERROR,
                                            results: [],
                                            count: 0
                                        }
                                    })
                                }),
							}
						},
					}
            }
            
        case types.LIKE_CONTENT:
            if (action.meta.params.contentType !== "comment") return state

            switch (action.meta.status) {
                case status.BEGIN:
                    return {
                        ...state,
                        [rootCommentContentType]: {
                            ...state[rootCommentContentType],
                            [rootCommentContentUid]: {
                                ...state[rootCommentContentType][rootCommentContentUid],
                                data: state[rootCommentContentType][rootCommentContentUid].data.map((comment) => {
                                    if (comment.uid !== uid) {
                                        return {
                                            ...comment,
                                            replies: comment.replies && {
                                                ...comment.replies,
                                                results: comment.replies.results.map(reply => {
                                                    if (reply.uid !== uid) return reply

                                                    return {
                                                        ...reply,
                                                        like_count: reply.like_count + 1
                                                    }
                                                })
                                            } 
                                        }
                                    }
                                    
                                    return {
                                        ...comment,
                                        like_count: comment.like_count + 1,
                                        replies: comment.replies && {
                                            ...comment.replies,
                                            results: comment.replies.results.map(reply => {
                                                if (reply.uid !== uid) return reply

                                                return {
                                                    ...reply,
                                                    like_count: reply.like_count + 1
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        },
                    }
                case status.SUCCESS:
                    return state
                case status.ERROR:
                    return {
                        ...state,
                        [rootCommentContentType]: {
                            ...state[rootCommentContentType],
                            [rootCommentContentUid]: {
                                ...state[rootCommentContentType][rootCommentContentUid],
                                data: state[rootCommentContentType][rootCommentContentUid].data.map((comment) => {
                                    if (comment.uid !== uid) {
                                        return {
                                            ...comment,
                                            replies: comment.replies && {
                                                ...comment.replies,
                                                results: comment.replies.results.map(reply => {
                                                    if (reply.uid !== uid) return reply

                                                    return {
                                                        ...reply,
                                                        like_count: reply.like_count - 1
                                                    }
                                                })
                                            }
                                        }
                                    }
                                    
                                    return {
                                        ...comment,
                                        like_count: comment.like_count - 1,
                                        replies: comment.replies && {
                                            ...comment.replies,
                                            results: comment.replies.results.map(reply => {
                                                if (reply.uid !== uid) return reply

                                                return {
                                                    ...reply,
                                                    like_count: reply.like_count - 1
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        },
                    }
            }

        case types.DELETE_LIKE:
            if (action.meta.params.contentType !== "comment") return state

            switch (action.meta.status) {
                case status.BEGIN:
                    return {
                        ...state,
                        [rootCommentContentType]: {
                            ...state[rootCommentContentType],
                            [rootCommentContentUid]: {
                                ...state[rootCommentContentType][rootCommentContentUid],
                                data: state[rootCommentContentType][rootCommentContentUid].data.map((comment) => {
                                    if (comment.uid !== uid) {
                                        return {
                                            ...comment,
                                            replies: comment.replies && {
                                                ...comment.replies,
                                                results: comment.replies.results.map(reply => {
                                                    if (reply.uid !== uid) return reply

                                                    return {
                                                        ...reply,
                                                        like_count: reply.like_count - 1
                                                    }
                                                })
                                            }
                                        }
                                    }
                                    
                                    return {
                                        ...comment,
                                        like_count: comment.like_count - 1,
                                        replies: comment.replies && {
                                            ...comment.replies,
                                            results: comment.replies.results.map(reply => {
                                                if (reply.uid !== uid) return reply

                                                return {
                                                    ...reply,
                                                    like_count: reply.like_count - 1
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        },
                    }
                case status.SUCCESS:
                    return state
                case status.ERROR:
                    return {
                        ...state,
                        [rootCommentContentType]: {
                            ...state[rootCommentContentType],
                            [rootCommentContentUid]: {
                                ...state[rootCommentContentType][rootCommentContentUid],
                                data: state[rootCommentContentType][rootCommentContentUid].data.map((comment) => {
                                    if (comment.uid !== uid) {
                                        return {
                                            ...comment,
                                            replies: comment.replies && {
                                                ...comment.replies,
                                                results: comment.replies.results.map(reply => {
                                                    if (reply.uid !== uid) return reply

                                                    return {
                                                        ...reply,
                                                        like_count: reply.like_count + 1
                                                    }
                                                })
                                            }
                                        }
                                    }
                                    
                                    return {
                                        ...comment,
                                        like_count: comment.like_count + 1,
                                        replies: comment.replies && {
                                            ...comment.replies,
                                            results: comment.replies.results.map(reply => {
                                                if (reply.uid !== uid) return reply

                                                return {
                                                    ...reply,
                                                    like_count: reply.like_count + 1
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        },
                    }
            }

		default:
			return state;
	}
};

export default comments;