import styles from './shareelement.module.css'

import React, { useEffect, useState } from 'react'

import { ogImageUrl } from '../../config'

import Icon from '../Icon'
import Tooltip from '../Tooltip'

import { useTrack } from '../../hooks/tracking'

const REDIRECT_URI = "https://musicboard.app"
const FACEBOOK_APP_ID = "990256672716610"
const DESCRIPTION = "Discover Music - Discover what music your friends are loving at the moment and share your favorites. Musicboard is a collected database of ratings and reviews for the worlds music."
const DEFAULT_IMAGE = ogImageUrl

export default function ShareElement({ type, title, image, description, className, style }) {
    const track = useTrack()

    const [copiedLink, setCopiedLink] = useState(false)

    useEffect(() => {
        if (copiedLink) {
            setTimeout(() => {
                setCopiedLink(false)
            }, 3000)
        }
    }, [ copiedLink ])

    function copyText(e) {
        e.stopPropagation()
        
        track(
            "Share", 
            {
                "ref": "element",
                "type": "copy",
                "content_type": type,
                "title": title,
            }
        );

        setCopiedLink(true)
        const element = document.getElementById("copy-input")
        /* Select the text field */
        element.select();
        element.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");
    }

    return (
        <div className={`flex-row ${className}`} style={{ ...style }}>
            <div className="margin-right-8" style={{ height: 20 }}>
                <input 
                id="copy-input" 
                onClick={copyText} 
                style={{ fontSize: 12 }} 
                className={styles.link}
                value={window.location.href}
                type="text"
                readOnly
                spellCheck="false"
                />
            </div>

            <a 
            id={`share-tip-twitter`} 
            className={styles.iconWrapper}
            target="_blank" 
            href={`https://x.com/intent/tweet?text=${title}%20on%20%40musicboardapp%3A%20${window.location.href}`}
            onClick={(e) => {
                e.stopPropagation()
                track(
                    "Share", 
                    { 
                        "ref": "element",
                        "type": "twitter",
                        "content_type": type,
                        "title": title,
                    }
                );
            }}
            >
                <Icon className="margin-left-8 margin-right-12" size={16} icon="x-filled" color="var(--black)" />
            </a>

            <div
            id={`share-tip-more`} 
            className={styles.iconWrapper}
            >
                <div style={{ height: 16, width: 18 }} className='flex-center'>
                    <Icon className="margin-left-12 margin-right-8" size={22} icon="reply" color="var(--black)" style={{ transform: 'rotateY(180deg)', marginTop: -1 }} />
                </div>
            </div>

            <Tooltip className={styles.tip} text={copiedLink ? "Copied link" : "Copy link"} id={"copy-input"} offset={10} />
            
            <Tooltip text={"Share to X"} id={`share-tip-twitter`} offset={10} />
            <Tooltip text={"More sharing"} id={`share-tip-more`} offset={10} />
        </div>
    )
}