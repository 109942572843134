import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import StickyBox from 'react-sticky-box'

import { useAudio } from '../../hooks/audio/audio'

export default function NewStickyScrolling({ enabled, offsetTop, offsetBottom=64, children, className, style }) {

    //Hooks
    const { isMounted } = useAudio()

    //State
    const [bottomRailHeight, setBottomRailHeight] = useState(0)

    useEffect(() => {
        // Set up resize observer to watch for height changes
        const resizeObserver = new ResizeObserver(entries => {
            const entry = entries[0];
            if (entry) {
                setBottomRailHeight(entry.contentRect.height);
            }
        });

        // Set up mutation observer to watch for element being added/removed
        const mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    const element = document.getElementById('pw-oop-bottom_rail');
                    if (element) {
                        resizeObserver.observe(element);
                    } else {
                        setBottomRailHeight(0);
                    }
                }
            });
        });

        // Start observing DOM changes
        mutationObserver.observe(document.body, {
            childList: true,
            subtree: true
        });

        // Check if element exists initially
        const element = document.getElementById('pw-oop-bottom_rail');
        if (element) {
            resizeObserver.observe(element);
        }

        // Cleanup
        return () => {
            resizeObserver.disconnect();
            mutationObserver.disconnect();
        };
    }, []);

    //Variables
    const defaultOffsetTop = 56
    const defaultOffsetBottom = offsetBottom + (isMounted ? 87 : 0) + bottomRailHeight
    
    if (!enabled) {
        return (
            <div className={className} style={style}>
                {children}
            </div>
        )
    }
        
    return (
        <StickyBox 
        key={`StickyScrolling-${bottomRailHeight}`}
        offsetBottom={defaultOffsetBottom} 
        offsetTop={typeof offsetTop === "number" ? offsetTop : defaultOffsetTop} 
        className={className} 
        style={style}
        >
            {children}
        </StickyBox>
    )
}
