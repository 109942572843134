import React, { useEffect, useReducer, useContext, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { types } from '../constants/action-types';

const ThemeContext = React.createContext();

export const light = {
    //Main scale
    black: 'rgb(29,30,32)',
    darkGrey: 'rgb(60,61,63)',

    grey: 'rgb(174,174,174)',
    highDarkGrey: 'rgb(128,128,128)',
    
    white: 'rgb(255,255,255)',
    temporaryTransparentBtnGrey: 'rgb(190,190,190)',
    
    lightGrey: 'rgb(238,238,238)',
    lightGrey2: 'rgb(226,226,226)',
    lightGrey3: 'rgb(206,206,206)',

    darkLightGrey: 'rgb(208,208,208)',
    lightestGrey: 'rgb(245,245,245)',
    
    //Main
    primary: 'rgb(0,153,245)',
    accent: 'rgb(63,176,240)',
    
    //Colors
    blue: 'rgb(0,153,245)',
    deepBlue: 'rgb(18,20,253)',
    green: 'rgb(48,209,88)',
    red: 'rgb(255,69,58)',
    yellow: 'rgb(254,183,1)',

    bronze: 'rgb(205,127,50)',
    silver: 'rgb(192,192,192)',
    gold: 'rgb(255,215,0)',
    diamond: 'rgb(0,191,255)',

    //Other
    textColor: 'rgb(29,30,32)',
    constantWhite: 'rgb(255,255,255)',
    hoverColor: 'rgb(238,238,238)',
}
export const lightSettings = {
    "shadow-light": "rgb(60 66 87 / 8%) 0px 2px 5px 0px",
    "shadow-dark": "rgb(60 66 87 / 14%) 0px 2px 5px 0px",
    "shadow-light-reverse": "rgb(60 66 87 / 14%) 0px -2px 5px 0px",
    "shadow-large-light": "0px 4px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark": "0px 4px 12px rgba(0,0,0,0.24)",
    "shadow-large-light-reverse": "0px -2px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark-reverse": "0px -2px 12px rgba(0,0,0,0.24)",
    
    "shadow-clickable": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                        rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(60 66 87 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                              rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                              rgb(60 66 87 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               var(--accent036) 0px 0px 0px 4px, 
                               rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                               rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                               rgb(60 66 87 / 8%) 0px 2px 5px 0px`,
    
    "shadow-input": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
}

export const dark = {
    //Main scale
    lightestGrey: 'rgb(14,15,17)',
    white: 'rgb(24,26,28)',
    lightGrey: 'rgb(36,37,39)',
    lightGrey2: 'rgb(52,53,55)',
    lightGrey3: 'rgb(68,69,71)',
    darkLightGrey: 'rgb(61,62,64)',
    grey: 'rgb(125,126,128)',
    highDarkGrey: 'rgb(160,161,164)',
    darkGrey: 'rgb(200,202,206)',
    black: 'rgb(228,230,234)',

    //Main
    primary: 'rgb(0,153,245)',
    accent: 'rgb(63,176,240)',

    //Colors
    blue: 'rgb(0,153,245)',
    deepBlue: 'rgb(18,20,253)',
    green: 'rgb(48,209,88)',
    red: 'rgb(255,69,58)',
    yellow: 'rgb(254,183,1)',

    bronze: 'rgb(205,127,50)',
    silver: 'rgb(192,192,192)',
    gold: 'rgb(255,215,0)',
    diamond: 'rgb(0,191,255)',

    //Other
    textColor: 'rgb(228,230,234)',
    constantWhite: 'rgb(228,230,234)',
    hoverColor: 'rgb(36,37,39)',
}

export const darkSettings = {
    "shadow-light": "rgb(0 / 10%) 0px 2px 5px 0px",
    "shadow-dark": "rgb(0 / 14%) 0px 2px 5px 0px",
    "shadow-light-reverse": "rgb(0 / 10%) 0px -2px 5px 0px",
    "shadow-large-light": "0px 4px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark": "0px 4px 12px rgba(0,0,0,0.24)",
    "shadow-large-light-reverse": "0px -2px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark-reverse": "0px -2px 12px rgba(0,0,0,0.24)",

    "shadow-clickable": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                        rgb(186 190 210 / 16%) 0px 0px 0px 1px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                              rgb(186 190 210 / 22%) 0px 0px 0px 1px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                              rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               var(--accent036) 0px 0px 0px 4px, 
                               rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                               rgb(186 190 210 / 20%) 0px 0px 0px 1px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                               rgb(0 0 0 / 8%) 0px 2px 5px 0px`,

    "shadow-input": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(186 190 210 / 16%) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(186 190 210 / 16%) 0px 0px 0px 1px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(186 190 210 / 16%) 0px 0px 0px 1px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
}

export const black = {
    //Main scale
    lightestGrey: 'rgb(0,0,0)',
    white: 'rgb(0,0,0)',
    lightGrey: 'rgb(34,34,36)',
    lightGrey2: 'rgb(48,48,50)',
    lightGrey3: 'rgb(62,62,64)',
    darkLightGrey: 'rgb(48,48,50)',
    grey: 'rgb(126,126,130)',
    highDarkGrey: 'rgb(160,160,164)',
    darkGrey: 'rgb(202,202,207)',
    black: 'rgb(255,255,255)',

    //Main
    primary: 'rgb(0,153,245)',
    accent: 'rgb(63,176,240)',

    //Colors
    blue: 'rgb(0,153,245)',
    deepBlue: 'rgb(18,20,253)',
    green: 'rgb(48,209,88)',
    red: 'rgb(255,69,58)',
    yellow: 'rgb(254,183,1)',

    bronze: 'rgb(205,127,50)',
    silver: 'rgb(192,192,192)',
    gold: 'rgb(255,215,0)',
    diamond: 'rgb(0,191,255)',

    //Other
    textColor: 'rgb(255,255,255)',
    constantWhite: 'rgb(255,255,255)',
    hoverColor: 'rgb(34,34,36)',
}

export const blackSettings = {
    "shadow-light": "rgb(34 36 36 / 100%) 0px 0px 0px 1px",
    "shadow-dark": "rgb(34 36 36 / 100%) 0px 0px 0px 1px",
    "shadow-light-reverse": "rgb(34 36 36 / 100%) 0px 0px 0px 1px",
    "shadow-large-light": "rgb(34 36 36 / 100%) 0px 0px 0px 1px",
    "shadow-large-dark": "rgb(34 36 36 / 100%) 0px 0px 0px 1px",
    "shadow-large-light-reverse": "rgb(34 36 36 / 100%) 0px 0px 0px 1px",
    "shadow-large-dark-reverse": "rgb(34 36 36 / 100%) 0px 0px 0px 1px",

    "shadow-clickable": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                        rgb(195 195 200 / 16%) 0px 0px 0px 1px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                              rgb(195 195 200 / 22%) 0px 0px 0px 1px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                              rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               var(--accent036) 0px 0px 0px 4px, 
                               rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                               rgb(195 195 200 / 20%) 0px 0px 0px 1px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                               rgb(0 0 0 / 8%) 0px 2px 5px 0px`,

    "shadow-input": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(195 195 200 / 16%) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(195 195 200 / 16%) 0px 0px 0px 1px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(195 195 200 / 16%) 0px 0px 0px 1px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
}

export const green = {
    //Main scale
    lightestGrey: 'rgb(10,22,10)',
    white: 'rgb(20,35,21)',
    lightGrey: 'rgb(34,45,34)', // 'rgb(36,37,39)',
    lightGrey2: 'rgb(49,60,49)', // 'rgb(52,53,55)',
    lightGrey3: 'rgb(64,76,64)', // 'rgb(68,69,71)',
    darkLightGrey: 'rgb(59,71,59)', // 'rgb(61,62,64)',
    grey: 'rgb(124,135,124)',
    highDarkGrey: 'rgb(156,166,156)',
    darkGrey: 'rgb(201,212,201)',
    black: 'rgb(228,238,228)',

    //Main
    primary: 'rgb(48,209,88)',
    accent: 'rgb(77,254,121)',

    //Colors
    blue: 'rgb(48,209,88)',
    deepBlue: 'rgb(18,20,253)',
    green: 'rgb(48,209,88)',
    red: 'rgb(48,209,88)',
    yellow: 'rgb(48,209,88)',

    bronze: 'rgb(205,127,50)',
    silver: 'rgb(192,192,192)',
    gold: 'rgb(255,215,0)',
    diamond: 'rgb(0,191,255)',

    //Other
    textColor: 'rgb(228,238,228)',
    constantWhite: 'rgb(228,238,228)',
    hoverColor: 'rgb(34,45,34)',
}

export const greenSettings = {
    "shadow-light": "rgb(0 0 0 / 10%) 0px 2px 5px 0px",
    "shadow-dark": "rgb(0 0 0 / 14%) 0px 2px 5px 0px",
    "shadow-light-reverse": "rgb(0 0 0 / 10%) 0px -2px 5px 0px",
    "shadow-large-light": "0px 4px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark": "0px 4px 12px rgba(0,0,0,0.24)",
    "shadow-large-light-reverse": "0px -2px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark-reverse": "0px -2px 12px rgba(0,0,0,0.24)",
    
    "shadow-clickable": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                        rgb(190 210 190 / 16%) 0px 0px 0px 1px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                              rgb(190 210 190 / 22%) 0px 0px 0px 1px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                              rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               var(--accent036) 0px 0px 0px 4px, 
                               rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                               rgb(190 210 190 / 20%) 0px 0px 0px 1px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                               rgb(0 0 0 / 8%) 0px 2px 5px 0px`,

    "shadow-input": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(190 210 190 / 16%) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(190 210 190 / 16%) 0px 0px 0px 1px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(190 210 190 / 16%) 0px 0px 0px 1px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
}

export const red = {
    //Main scale
    lightestGrey: 'rgb(22,10,10)',
    white: 'rgb(35,20,20)',
    lightGrey: 'rgb(45,34,34)',
    lightGrey2: 'rgb(60,49,49)',
    lightGrey3: 'rgb(76,64,64)',
    darkLightGrey: 'rgb(71,59,59)',
    grey: 'rgb(135,124,124)',
    highDarkGrey: 'rgb(166,156,156)',
    darkGrey: 'rgb(212,201,201)',
    black: 'rgb(238,228,228)',

    //Main
    primary: 'rgb(255,69,58)',
    accent: 'rgb(255,127,99)',

    //Colors
    blue: 'rgb(255,69,58)',
    deepBlue: 'rgb(18,20,253)',
    green: 'rgb(255,69,58)',
    red: 'rgb(255,69,58)',
    yellow: 'rgb(255,69,58)',

    bronze: 'rgb(205,127,50)',
    silver: 'rgb(192,192,192)',
    gold: 'rgb(255,215,0)',
    diamond: 'rgb(0,191,255)',

    //Other
    textColor: 'rgb(238,228,228)',
    constantWhite: 'rgb(238,228,228)',
    hoverColor: 'rgb(45,34,34)',
}

export const redSettings = {
    "shadow-light": "rgb(0 0 0 / 10%) 0px 2px 5px 0px",
    "shadow-dark": "rgb(0 0 0 / 14%) 0px 2px 5px 0px",
    "shadow-light-reverse": "rgb(0 0 0 / 10%) 0px -2px 5px 0px",
    "shadow-large-light": "0px 4px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark": "0px 4px 12px rgba(0,0,0,0.24)",
    "shadow-large-light-reverse": "0px -2px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark-reverse": "0px -2px 12px rgba(0,0,0,0.24)",

    "shadow-clickable": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                        rgb(210 190 190 / 16%) 0px 0px 0px 1px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                              rgb(210 190 190 / 22%) 0px 0px 0px 1px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                              rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               var(--accent036) 0px 0px 0px 4px, 
                               rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                               rgb(210 190 190 / 20%) 0px 0px 0px 1px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                               rgb(0 0 0 / 8%) 0px 2px 5px 0px`,

    "shadow-input": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(210 190 190 / 16%) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(210 190 190 / 16%) 0px 0px 0px 1px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(210 190 190 / 16%) 0px 0px 0px 1px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
}

export const blue = {
    //Main scale
    lightestGrey: 'rgb(10,10,22)',
    white: 'rgb(20,20,35)',
    lightGrey: 'rgb(34,34,45)',
    lightGrey2: 'rgb(49,49,60)',
    lightGrey3: 'rgb(64,64,76)',
    darkLightGrey: 'rgb(59,59,71)',
    grey: 'rgb(124,124,135)',
    highDarkGrey: 'rgb(156,156,166)',
    darkGrey: 'rgb(201,201,212)',
    black: 'rgb(228,228,238)',

    //Main
    primary: 'rgb(0,153,245)',
    accent: 'rgb(63,176,240)',
    
    //Colors
    blue: 'rgb(0,153,245)',
    deepBlue: 'rgb(18,20,253)',
    green: 'rgb(0,153,245)',
    red: 'rgb(0,153,245)',
    yellow: 'rgb(0,153,245)',

    bronze: 'rgb(205,127,50)',
    silver: 'rgb(192,192,192)',
    gold: 'rgb(255,215,0)',
    diamond: 'rgb(0,191,255)',

    //Other
    textColor: 'rgb(228,228,238)',
    constantWhite: 'rgb(228,228,238)',
    hoverColor: 'rgb(34,34,45)',
}

export const blueSettings = {
    "shadow-light": "rgb(0 0 0 / 10%) 0px 2px 5px 0px",
    "shadow-dark": "rgb(0 0 0 / 14%) 0px 2px 5px 0px",
    "shadow-light-reverse": "rgb(0 0 0 / 10%) 0px -2px 5px 0px",
    "shadow-large-light": "0px 4px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark": "0px 4px 12px rgba(0,0,0,0.24)",
    "shadow-large-light-reverse": "0px -2px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark-reverse": "0px -2px 12px rgba(0,0,0,0.24)",

    "shadow-clickable": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                        rgb(190 190 210 / 16%) 0px 0px 0px 1px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                              rgb(190 190 210 / 22%) 0px 0px 0px 1px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                              rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               var(--accent036) 0px 0px 0px 4px, 
                               rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                               rgb(190 190 210 / 20%) 0px 0px 0px 1px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                               rgb(0 0 0 / 8%) 0px 2px 5px 0px`,

    "shadow-input": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(190 190 210 / 16%) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(190 190 210 / 16%) 0px 0px 0px 1px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(190 190 210 / 16%) 0px 0px 0px 1px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
}

export const pink = {
    //Main scale
    lightestGrey: 'rgb(22,10,22)',
    white: 'rgb(35,20,35)',
    lightGrey: 'rgb(45,34,45)',
    lightGrey2: 'rgb(60,49,60)',
    lightGrey3: 'rgb(76,64,76)',
    darkLightGrey: 'rgb(71,59,71)',
    grey: 'rgb(135,124,135)',
    highDarkGrey: 'rgb(166,156,166)',
    darkGrey: 'rgb(212,201,212)',
    black: 'rgb(238,228,238)',

    //Main
    primary: 'rgb(254,1,212)',
    accent: 'rgb(255,134,235)',

    //Colors
    blue: 'rgb(254,1,212)',
    deepBlue: 'rgb(18,20,253)',
    green: 'rgb(254,1,212)',
    red: 'rgb(254,1,212)',
    yellow: 'rgb(254,1,212)',

    bronze: 'rgb(205,127,50)',
    silver: 'rgb(192,192,192)',
    gold: 'rgb(255,215,0)',
    diamond: 'rgb(0,191,255)',

    //Other
    textColor: 'rgb(238,228,238)',
    constantWhite: 'rgb(238,228,238)',
    hoverColor: 'rgb(45,34,45)',
}

export const pinkSettings = {
    "shadow-light": "rgb(0 0 0 / 10%) 0px 2px 5px 0px",
    "shadow-dark": "rgb(0 0 0 / 14%) 0px 2px 5px 0px",
    "shadow-light-reverse": "rgb(0 0 0 / 10%) 0px -2px 5px 0px",
    "shadow-large-light": "0px 4px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark": "0px 4px 12px rgba(0,0,0,0.24)",
    "shadow-large-light-reverse": "0px -2px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark-reverse": "0px -2px 12px rgba(0,0,0,0.24)",

    "shadow-clickable": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                        rgb(210 190 210 / 16%) 0px 0px 0px 1px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                              rgb(210 190 210 / 22%) 0px 0px 0px 1px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                              rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               var(--accent036) 0px 0px 0px 4px, 
                               rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                               rgb(210 190 210 / 20%) 0px 0px 0px 1px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                               rgb(0 0 0 / 8%) 0px 2px 5px 0px`,

    "shadow-input": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(210 190 210 / 16%) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(210 190 210 / 16%) 0px 0px 0px 1px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(210 190 210 / 16%) 0px 0px 0px 1px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
}

export const teal = {
    //Main scale
    lightestGrey: 'rgb(10,22,22)',
    white: 'rgb(20,35,35)',
    lightGrey: 'rgb(34,45,45)',
    lightGrey2: 'rgb(49,60,60)',
    lightGrey3: 'rgb(64,76,76)',
    darkLightGrey: 'rgb(59,71,71)',
    grey: 'rgb(124,135,135)',
    highDarkGrey: 'rgb(156,166,166)',
    darkGrey: 'rgb(201,212,212)',
    black: 'rgb(228,238,238)',

    //Main
    primary: 'rgb(1,254,212)',
    accent: 'rgb(134,255,235)',
    
    //Colors
    blue: 'rgb(1,254,212)',
    deepBlue: 'rgb(18,20,253)',
    green: 'rgb(1,254,212)',
    red: 'rgb(1,254,212)',
    yellow: 'rgb(1,254,212)',

    bronze: 'rgb(205,127,50)',
    silver: 'rgb(192,192,192)',
    gold: 'rgb(255,215,0)',
    diamond: 'rgb(0,191,255)',

    //Other
    textColor: 'rgb(228,238,238)',
    constantWhite: 'rgb(228,238,238)',
    hoverColor: 'rgb(34,45,45)',
}

export const tealSettings = {
    "shadow-light": "rgb(0 0 0 / 10%) 0px 2px 5px 0px",
    "shadow-dark": "rgb(0 0 0 / 14%) 0px 2px 5px 0px",
    "shadow-light-reverse": "rgb(0 0 0 / 10%) 0px -2px 5px 0px",
    "shadow-large-light": "0px 4px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark": "0px 4px 12px rgba(0,0,0,0.24)",
    "shadow-large-light-reverse": "0px -2px 12px rgba(0,0,0,0.12)",
    "shadow-large-dark-reverse": "0px -2px 12px rgba(0,0,0,0.24)",

    "shadow-clickable": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                        rgb(190 210 210 / 16%) 0px 0px 0px 1px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                              rgb(190 210 210 / 22%) 0px 0px 0px 1px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                              rgb(0 0 0 / 8%) 0px 2px 5px 0px`,
    "shadow-clickable-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               var(--accent036) 0px 0px 0px 4px, 
                               rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                               rgb(210 190 210 / 20%) 0px 0px 0px 1px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(0 0 0 / 12%) 0px 3px 9px 0px, 
                               rgb(0 0 0 / 8%) 0px 2px 5px 0px`,

    "shadow-input": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(190 210 210 / 16%) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-hover": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(190 210 210 / 16%) 0px 0px 0px 1px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    "shadow-input-active": `rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(190 210 210 / 16%) 0px 0px 0px 1px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
}


export const useTheme = () => {
    const { state, dispatch } = useContext(ThemeContext)

    return {
        ...state,
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_COLORS":
            return {
                ...state,
                colors: (
                    action.payload === "dark" ? 
                        dark
                    : action.payload === "black" ? 
                        black
                    : action.payload === "green" ? 
                        green
                    : action.payload === "red" ? 
                        red
                    : action.payload === "blue" ? 
                        blue
                    : action.payload === "pink" ? 
                        pink
                    : action.payload === "teal" ? 
                        teal
                    : action.payload === "light" ? 
                        light
                    : 
                        dark
                ),
                mode: action.payload
            }
        default:
            throw new Error()
    }
}

const defaultTheme = { 
    colors: dark,
    mode: "dark"
}
  
const ThemeProvider = ({ init, children }) => {
    //General
    const [ state, dispatch ] = useReducer(reducer, defaultTheme);
    const reduxDispatch = useDispatch()

    //Data
    const isDarkMode = useSelector(state => state.local.isDarkMode)
    const theme = useSelector(state => state.local.theme)
    const tempTheme = useSelector(state => state.local.tempTheme)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const isPro = useSelector(state => state.me.is_pro)
    const tier = useSelector(state => state.me.tier)
    const isPlatinum = isPro && tier === 1

    //Set initial theme
    useEffect(() => {
        if (!theme) {
            if (isDarkMode) {
                reduxDispatch({ type: types.SET_THEME, payload: "dark" })
            } else {
                reduxDispatch({ type: types.SET_THEME, payload: "light" })
            }
        }
    }, [])

    function updateColors(theme, force) {
        //Get the correct scheme and settings
        const scheme = (
            theme === "dark" ? 
                dark
            : theme === "light" ? 
                light
            : ((isLoggedIn && isPlatinum) || force) && theme === "black" ? 
                black
            : ((isLoggedIn && isPlatinum) || force) && theme === "green" ? 
                green
            : ((isLoggedIn && isPlatinum) || force) && theme === "red" ? 
                red
            : ((isLoggedIn && isPlatinum) || force) && theme === "blue" ? 
                blue
            : ((isLoggedIn && isPlatinum) || force) && theme === "pink" ? 
                pink
            : ((isLoggedIn && isPlatinum) || force) && theme === "teal" ? 
                teal
            :
                dark
        );

        const settings = (
            theme === "dark" ? 
                darkSettings
            : theme === "light" ? 
                lightSettings
            : ((isLoggedIn && isPlatinum) || force) && theme === "black" ? 
                blackSettings
            : ((isLoggedIn && isPlatinum) || force) && theme === "green" ? 
                greenSettings
            : ((isLoggedIn && isPlatinum) || force) && theme === "red" ? 
                redSettings
            : ((isLoggedIn && isPlatinum) || force) && theme === "blue" ? 
                blueSettings
            : ((isLoggedIn && isPlatinum) || force) && theme === "pink" ? 
                pinkSettings
            : ((isLoggedIn && isPlatinum) || force) && theme === "teal" ? 
                tealSettings
            :
                darkSettings
        );

        //Set all the main colors
        for (const [key, value] of Object.entries(scheme)) {
            document.documentElement.style.setProperty(`--${key}`, value);

            //Normal
            document.documentElement.style.setProperty( `--${key}`, value );
            //Transparency
            document.documentElement.style.setProperty( `--${key}007`, value.replace("rgb", "rgba").replace(")", ",0.07)") );
            document.documentElement.style.setProperty( `--${key}012`, value.replace("rgb", "rgba").replace(")", ",0.12)") );
            document.documentElement.style.setProperty( `--${key}020`, value.replace("rgb", "rgba").replace(")", ",0.20)") );
            document.documentElement.style.setProperty( `--${key}025`, value.replace("rgb", "rgba").replace(")", ",0.25)") );
            document.documentElement.style.setProperty( `--${key}030`, value.replace("rgb", "rgba").replace(")", ",0.30)") );
            document.documentElement.style.setProperty( `--${key}036`, value.replace("rgb", "rgba").replace(")", ",0.36)") );
            document.documentElement.style.setProperty( `--${key}040`, value.replace("rgb", "rgba").replace(")", ",0.40)") );
            document.documentElement.style.setProperty( `--${key}050`, value.replace("rgb", "rgba").replace(")", ",0.50)") );
            document.documentElement.style.setProperty( `--${key}060`, value.replace("rgb", "rgba").replace(")", ",0.60)") );
            document.documentElement.style.setProperty( `--${key}070`, value.replace("rgb", "rgba").replace(")", ",0.70)") );
            document.documentElement.style.setProperty( `--${key}075`, value.replace("rgb", "rgba").replace(")", ",0.75)") );
            document.documentElement.style.setProperty( `--${key}080`, value.replace("rgb", "rgba").replace(")", ",0.80)") );
            document.documentElement.style.setProperty( `--${key}085`, value.replace("rgb", "rgba").replace(")", ",0.85)") );
            document.documentElement.style.setProperty( `--${key}090`, value.replace("rgb", "rgba").replace(")", ",0.90)") );
            document.documentElement.style.setProperty( `--${key}092`, value.replace("rgb", "rgba").replace(")", ",0.92)") );
            document.documentElement.style.setProperty( `--${key}095`, value.replace("rgb", "rgba").replace(")", ",0.95)") );
            document.documentElement.style.setProperty( `--${key}097`, value.replace("rgb", "rgba").replace(")", ",0.97)") );
            //Transparent
            document.documentElement.style.setProperty( `--${key}Transparent`, value.replace("rgb", "rgba").replace(")", ",0)") );
        }

        //Set the settings
        for (const [key, value] of Object.entries(settings)) {
            //Normal
            document.documentElement.style.setProperty( `--${key}`, value );
        }

        //Set the colors of full background linear gradients
        const lightestGreyNumbers = scheme.lightestGrey.replace("rgb(", "").replace(")", "")
        const whiteNumbers = scheme.white.replace("rgb(", "").replace(")", "")

        document.documentElement.style.setProperty("--full-background-dark-sides", `linear-gradient(
            90deg,
            rgba(${lightestGreyNumbers}, 1.000000) 0%,
            rgba(${lightestGreyNumbers}, 0.978938) 2%,
            rgba(${lightestGreyNumbers}, 0.913325) 4%,
            rgba(${lightestGreyNumbers}, 0.805633) 6%,
            rgba(${lightestGreyNumbers}, 0.670197) 8%,
            rgba(${lightestGreyNumbers}, 0.530396) 10%,
            rgba(${lightestGreyNumbers}, 0.407353) 12%,
            rgba(${lightestGreyNumbers}, 0.312253) 14%,
            rgba(${lightestGreyNumbers}, 0.247561) 16%,
            rgba(${lightestGreyNumbers}, 0.211290) 18%,
            rgba(${lightestGreyNumbers}, 0.200000) 20%,
            rgba(${lightestGreyNumbers}, 0.200000) 80%,
            rgba(${lightestGreyNumbers}, 0.211290) 82%,
            rgba(${lightestGreyNumbers}, 0.247561) 84%,
            rgba(${lightestGreyNumbers}, 0.312253) 86%,
            rgba(${lightestGreyNumbers}, 0.407353) 88%,
            rgba(${lightestGreyNumbers}, 0.530396) 90%,
            rgba(${lightestGreyNumbers}, 0.670197) 92%,
            rgba(${lightestGreyNumbers}, 0.805633) 94%,
            rgba(${lightestGreyNumbers}, 0.913325) 96%,
            rgba(${lightestGreyNumbers}, 0.978938) 98%,
            rgba(${lightestGreyNumbers}, 1.000000) 100%
        ),
        linear-gradient(
            0deg,
            rgba(${lightestGreyNumbers}, 1.000000) 0%,
            rgba(${lightestGreyNumbers}, 0.973673) 6%,
            rgba(${lightestGreyNumbers}, 0.891657) 12%,
            rgba(${lightestGreyNumbers}, 0.757042) 18%,
            rgba(${lightestGreyNumbers}, 0.587746) 24%,
            rgba(${lightestGreyNumbers}, 0.412995) 30%,
            rgba(${lightestGreyNumbers}, 0.259191) 36%,
            rgba(${lightestGreyNumbers}, 0.140316) 42%,
            rgba(${lightestGreyNumbers}, 0.059451) 48%,
            rgba(${lightestGreyNumbers}, 0.014113) 54%,
            rgba(${lightestGreyNumbers}, 0.000000) 60%
        )`)

        document.documentElement.style.setProperty("--full-background-light-sides", `linear-gradient(
            90deg,
            rgba(${whiteNumbers}, 1.000000) 0%,
            rgba(${whiteNumbers}, 0.978938) 2%,
            rgba(${whiteNumbers}, 0.913325) 4%,
            rgba(${whiteNumbers}, 0.805633) 6%,
            rgba(${whiteNumbers}, 0.670197) 8%,
            rgba(${whiteNumbers}, 0.530396) 10%,
            rgba(${whiteNumbers}, 0.407353) 12%,
            rgba(${whiteNumbers}, 0.312253) 14%,
            rgba(${whiteNumbers}, 0.247561) 16%,
            rgba(${whiteNumbers}, 0.211290) 18%,
            rgba(${whiteNumbers}, 0.200000) 20%,
            rgba(${whiteNumbers}, 0.200000) 80%,
            rgba(${whiteNumbers}, 0.211290) 82%,
            rgba(${whiteNumbers}, 0.247561) 84%,
            rgba(${whiteNumbers}, 0.312253) 86%,
            rgba(${whiteNumbers}, 0.407353) 88%,
            rgba(${whiteNumbers}, 0.530396) 90%,
            rgba(${whiteNumbers}, 0.670197) 92%,
            rgba(${whiteNumbers}, 0.805633) 94%,
            rgba(${whiteNumbers}, 0.913325) 96%,
            rgba(${whiteNumbers}, 0.978938) 98%,
            rgba(${whiteNumbers}, 1.000000) 100%
        ),
        linear-gradient(
            0deg,
            rgba(${whiteNumbers}, 1.000000) 0%,
            rgba(${whiteNumbers}, 0.973673) 6%,
            rgba(${whiteNumbers}, 0.891657) 12%,
            rgba(${whiteNumbers}, 0.757042) 18%,
            rgba(${whiteNumbers}, 0.587746) 24%,
            rgba(${whiteNumbers}, 0.412995) 30%,
            rgba(${whiteNumbers}, 0.259191) 36%,
            rgba(${whiteNumbers}, 0.140316) 42%,
            rgba(${whiteNumbers}, 0.059451) 48%,
            rgba(${whiteNumbers}, 0.014113) 54%,
            rgba(${whiteNumbers}, 0.000000) 60%
        )`)

        document.documentElement.style.setProperty("--full-background-dark-no-sides", `linear-gradient(
            0deg,
            rgba(${lightestGreyNumbers}, 1.000000) 0%,
            rgba(${lightestGreyNumbers}, 0.978938) 6%,
            rgba(${lightestGreyNumbers}, 0.913325) 12%,
            rgba(${lightestGreyNumbers}, 0.805633) 18%,
            rgba(${lightestGreyNumbers}, 0.670197) 24%,
            rgba(${lightestGreyNumbers}, 0.530396) 30%,
            rgba(${lightestGreyNumbers}, 0.407353) 36%,
            rgba(${lightestGreyNumbers}, 0.312253) 42%,
            rgba(${lightestGreyNumbers}, 0.247561) 48%,
            rgba(${lightestGreyNumbers}, 0.211290) 54%,
            rgba(${lightestGreyNumbers}, 0.200000) 60%
        )`)

        document.documentElement.style.setProperty("--full-background-light-no-sides", `linear-gradient(
            0deg,
            rgba(${whiteNumbers}, 1.000000) 0%,
            rgba(${whiteNumbers}, 0.978938) 6%,
            rgba(${whiteNumbers}, 0.913325) 12%,
            rgba(${whiteNumbers}, 0.805633) 18%,
            rgba(${whiteNumbers}, 0.670197) 24%,
            rgba(${whiteNumbers}, 0.530396) 30%,
            rgba(${whiteNumbers}, 0.407353) 36%,
            rgba(${whiteNumbers}, 0.312253) 42%,
            rgba(${whiteNumbers}, 0.247561) 48%,
            rgba(${whiteNumbers}, 0.211290) 54%,
            rgba(${whiteNumbers}, 0.200000) 60%
        )`)

        //Update the theme color for iOS Safari
        if (theme === "dark") {
            document.getElementById("head-theme-color").content = "#181A1C"
        } else if (theme === "black") {
            document.getElementById("head-theme-color").content = "#000000"
        } else if (theme === "green") {
            document.getElementById("head-theme-color").content = "#142315"
        } else if (theme === "red") {
            document.getElementById("head-theme-color").content = "#231414"
        } else if (theme === "blue") {
            document.getElementById("head-theme-color").content = "#141423"
        } else if (theme === "pink") {
            document.getElementById("head-theme-color").content = "#231423"
        } else if (theme === "teal") {
            document.getElementById("head-theme-color").content = "#142323"
        } else if (theme === "light") {
            document.getElementById("head-theme-color").content = "#FFFFFF"
        }
    }

    //Update colors
    const firstUpdateTheme = useRef(true)
    useEffect(() => {
        if (firstUpdateTheme.current) {
            firstUpdateTheme.current = false
            return
        }

        if (tempTheme) {
            updateColors(tempTheme, true)
        } else {
            updateColors(theme)
        }

    }, [ tempTheme ])

    //Update colors
    useEffect(() => {
        updateColors(theme)

        if (
            (
                theme === "black" ||
                theme === "green" ||
                theme === "red" ||
                theme === "blue" ||
                theme === "pink" ||
                theme === "teal"
            ) &&
            (!isLoggedIn || !isPlatinum)
        ) {
            reduxDispatch({ type: types.SET_THEME, payload: "dark" })
        } else {
            dispatch({
                type: "UPDATE_COLORS",
                payload: theme,
            })
        }

    }, [ theme ])

    //Handle the theme query param to set the mode
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const theme = urlParams.get('theme')
        if (theme === "dark" || 
            theme === "black" || 
            theme === "green" || 
            theme === "red" || 
            theme === "blue" || 
            theme === "pink" || 
            theme === "teal" || 
            theme === "light"
        ) {
            reduxDispatch({ type: types.SET_THEME, payload: theme })
        }
    }, [])

    return (
        <ThemeContext.Provider value={{ state, dispatch }}>
            <div className={`${theme} theme-class ${theme === "light" ? "light" : "dark"} ${isLoggedIn ? "logged-in" : "logged-out"}`}>
                {children}
            </div>
        </ThemeContext.Provider>
    )
}
export default ThemeProvider;