import styles from './adcomponent.module.css'

import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { v4 } from 'uuid'
import Cookies from 'js-cookie';

import Icon from '../Icon'
import { RampUnit } from '../RampUnits'

function AdComponent({ 
    className, 
    type="", // "full" || "large" || "small" || "vertical" || "bottom" || "right"
    aboveTheFold,
    onAdCompleted=()=>{},
}) {
    //Params
	const showAds = true; // Cookies.get("Show-Test-Ads") === "true"
    
    //Responsive
    const isMobile = useMediaQuery({ maxWidth: 630 })

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const isPro = useSelector(state => state.me.is_pro)
    const allow_web_ads = useSelector(state => state.auth.healthCheck.allow_web_ads)
    
    //State
    const randomUid = useMemo(() => v4(), [])

    const [calculatedWidth, setCalculatedWidth] = useState(null)
    const [adLoaded, setAdLoaded] = useState(false)

    //Refs
    const adLoadedRef = useRef(false)

    //Keep track of ad loaded state
    useEffect(() => {
        adLoadedRef.current = adLoaded
    }, [adLoaded])

    //Keep calculated width updated by checking the width of the ad component wrapper, and listening for changes of window resize
    useEffect(() => {
        /*
        const adComponentWrapper = document.getElementById(`ad-component-wrapper-${randomUid}`)
        if (adComponentWrapper) {
            setCalculatedWidth(adComponentWrapper.clientWidth)
        }
        */
        
        const handleResize = () => {
            const adComponentWrapper = document.getElementById(`ad-component-wrapper-${randomUid}`)
            if (adComponentWrapper) {
                setCalculatedWidth(adComponentWrapper.clientWidth)
            }
        }
        
        handleResize()

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [randomUid])

    //Keep track of ad loaded, by checking if the ad component wrapper has any children, 
    // and listening for changes of ad component wrapper children
    // adLoaded should be true if ad component wrapper's first div child has height > 0
    useEffect(() => {
        const checkAdLoaded = () => {
            if (adLoadedRef.current) {
                return
            }

            const adComponentWrapper = document.getElementById(`ad-component-wrapper-${randomUid}`)
            if (adComponentWrapper) {
                const firstDiv = adComponentWrapper.querySelector('div')
                if (firstDiv && firstDiv.offsetHeight > 0) {
                    setAdLoaded(true)
                    onAdCompleted()
                }
            }
        }

        // Initial check
        checkAdLoaded()

        // Set up mutation observer to watch for changes
        const observer = new MutationObserver(checkAdLoaded)
        
        const adComponentWrapper = document.getElementById(`ad-component-wrapper-${randomUid}`)
        if (adComponentWrapper) {
            observer.observe(adComponentWrapper, {
                childList: true,
                subtree: true
            })
        }

        return () => observer.disconnect()
    }, [randomUid])

    //Prepare for render
    const margin = useMemo(() => 
        type === "full" ? 
            "margin-left-16 margin-right-16" 
        : type === "large" ? 
            "margin-left-16 margin-right-16" 
        : type === "small" ?
            "margin-left-16 margin-right-16"
        : type === "vertical" ?
            "margin-left-16 margin-right-16"
        : type === "bottom" ?
            ""
        : type === "right" ?
            ""
        :
            ""
    , [type])

    const adType = useMemo(() =>
        type === "full" ?
            `leaderboard_${aboveTheFold ? "atf" : "btf"}`
        : type === "large" ?
            `med_rect_${aboveTheFold ? "atf" : "btf"}`
        : type === "small" ?
            `med_rect_${aboveTheFold ? "atf" : "btf"}`
        : type === "vertical" ?
            `sky_${aboveTheFold ? "atf" : "btf"}`
        : 
            ""
    , [type, aboveTheFold])

    //Render
    if (!allow_web_ads) {
        return null
    }

    if (isLoggedIn && isPro) {
        return null
    }

    if (!showAds) {
        return null
    }

    return (
        <div 
        className={`${className} ${isMobile ? "" : margin}`} 
        style={adLoaded ? {} : { marginTop: 0 }}
        >
            <div 
            className='flex-center' 
            id={`ad-component-wrapper-${randomUid}`} 
            style={{ maxWidth: calculatedWidth ? calculatedWidth : undefined, overflow: 'hidden' }}
            >
                <RampUnit
                type={adType}
                />
            </div>

            {adLoaded &&
                <div className={"flex-center margin-top-12"}>
                    <Link to="/pro" className={`flex-center ${styles.removeAds}`}>
                        <Icon icon='close' size={16} style={{ marginRight: 2, transform: 'scale(0.95) translateY(0.2px)' }} />
                        <h6 style={{ fontSize: 12 }}>REMOVE ADS</h6>
                    </Link>
                </div>
            }
        </div>
    )
}

export default React.memo(AdComponent)