import React from 'react';

window.ramp = window.ramp || {};
window.ramp.que = window.ramp.que || [];
window.ramp.passiveMode = true;
window._pwRampComponentLoaded = window._pwRampComponentLoaded || false;

/*
'trendi_slideshow',
'trendi_video',
'site_skin',
'top_rail',
'right_rail',
'left_rail',
*/
const oopUnits = [
    'flex_leaderboard',
    'bottom_rail',
    'corner_ad_video',
];

const hiddenPaths = [
    ["/about"],
    ["/faq/storage"],
    ["/contact"],
    ["/contributors/artists"],
    ["/contributors"],
    ["/contributors/releases"],
    ["/album/new"],
    ["/artist/new"],
    ["/list/new"],
    ["/new"],
    ["/faq"],
    ["/groups"],
    ["/guidelines"],
    ["/onboarding"],
    ["/onboarding/personal"],
    ["/onboarding/genres"],
    ["/onboarding/albums"],
    ["/onboarding/users"],
    ["/onboarding/features"],
    ["/patch-notes"],
    ["/privacy"],
    ["/download"],
    ["/pro"],
    ["/reset-password"],
    ["/settings"],
    ["/settings/preferences"],
    ["/settings/billing"],
    ["/guidelines"],
    ["/terms"],
    ["/pro/complete"],
    ["/verify-email"],
    ["/album", "/edit"],
    ["/artist", "/edit"],
    ["/list", "/edit"],
]

const hiddenLoginPaths = [
    ["/lists/following"],
    ["/reviews/following"],
    ["/ratings/following"],
]

// destroy the units when componenent unmounts
const cleanUp = (parentId) => new Promise((resolve, reject) => {
    // possible that component was removed before first ad was created
    if (!window.ramp.settings || !window.ramp.settings.slots)
        return;

    delete window.ramp.forcePath;

    let slotsToRemove = [];
    Object.entries(window.ramp.settings.slots).forEach(([slotName, slot]) => {
        if (
            oopUnits.includes(slot.type)
            || slot.videoType === 'Bolt Player'
        ) {
            slotsToRemove.push(slotName);
        }
    });

    if (slotsToRemove.length > 0) {
        window.ramp.destroyUnits(slotsToRemove)
        .finally(() => resolve());
    }
});


export default class Ramp extends React.Component {
    constructor(props) {

        super(props);

        if (!props || !props.publisherId || !props.id) {
            console.error('publisherId and id are required props.');
            return;
        }
        this.init(props);

    }

    init ({publisherId, id, forcePath, userEmail}) {

        if (forcePath) {
            if (window?.ramp) {
                window.ramp.forcePath = forcePath;
            }
        }

        // make sure we only do this once per "app" load
        if (!window._pwRampComponentLoaded) {
            window._pwRampComponentLoaded = true;

            const configScript = document.createElement("script");
            configScript.src = `https://cdn.intergient.com/${publisherId}/${id}/ramp.js`;
            configScript.setAttribute('data-cfasync', 'false');

            document.head.appendChild(configScript);

            if (userEmail && userEmail !== "") {
                if (window?.ramp?.setUserProfile) {
                    window.ramp.setUserProfile({
                        eid: userEmail,
                        format: 'UUP1',
                    });
                }
            }
        }

        this.displayTaglessUnits();

    }

    displayTaglessUnits () {
        const skipTaglessUnits = () => {
            for (let i = 0; i < hiddenPaths.length; i++) {
                const path = hiddenPaths[i]
                if (this.props.forcePath.includes("/groups")) {
                    return true
                } else if (path.length === 1 && this.props.forcePath === path[0]) {
                    return true
                } else if (path.length === 2 && this.props.forcePath.includes(path[0]) && this.props.forcePath.includes(path[1])) {
                    return true
                }
            }
            for (let i = 0; i < hiddenLoginPaths.length; i++) {
                const path = hiddenLoginPaths[i]
                if (path.length === 1 && this.props.forcePath === path[0] && !this.props.isLoggedIn) {
                    return true
                }
            }
        }

        if (skipTaglessUnits()) {
            console.log("Skipping tagless units")
            return
        }

        /*
        {type: 'trendi_slideshow'},
        {type: 'trendi_video'},
        {type: 'site_skin'},
        {type: 'top_rail'},
        {type: 'right_rail'},
        {type: 'behind_page'},
        {type: 'in_image'},
        {type: 'above_page'},
        {type: 'inimg'},
        {type: 'skin'},
        {type: 'left_rail'},
        {type: 'sidebar'},
        {type: 'sticky_sidebar'},
        {type: 'in_content'},
        */

        window.ramp.que.push(() => {
            window.ramp.addUnits([
                {type: 'flex_leaderboard'},
                {type: 'bottom_rail'},
                {type: 'corner_ad_video'},
            ])
            .finally(() => {
                window.ramp.displayUnits();
            });
        });
    }

    componentDidUpdate (prevProps) {
        if (this.props.forcePath && prevProps.forcePath !== this.props.forcePath) {
            window.ramp.forcePath = this.props.forcePath;
            window.ramp.que.push(() => {
                window.ramp.setPath(this.props.forcePath)
                    .then(() => {
                        return cleanUp();
                    })
                    .then(() => {
                        this.displayTaglessUnits();
                    });
            });
            if (this.props.userEmail && this.props.userEmail !== "") {
                if (window?.ramp?.setUserProfile) {
                    window.ramp.setUserProfile({
                        eid: this.props.userEmail,
                        format: 'UUP1',
                    });
                }
            }
        }
    }

    componentWillUnmount () {
        window.ramp.que.push(() => {
            cleanUp();
        });
    }

    render() {
        return (
            null
        );
    }
}