import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';

import { status } from '../../../../constants/action-types';

import { logInWithSocial } from '../../../../actions/auth-actions';

import { useAlert } from '../../../../hooks/alert/alert';

import Icon from '../../../../components/Icon';
import Button from '../../../../components/Button'

export default function SignInWithGoogle({ className, isSignIn, code, redirect }) {
    //General
    const dispatch = useDispatch()
    const location = useLocation()
    const { openAlert } = useAlert()
    
    //Data
    const logInStatus = useSelector(state => state.auth.logInStatus)

    //State
    const [started, setStarted] = useState(false)

    //Start login based on code
    useEffect(() => {
        if (code && typeof code === "string" && code !== "") {
            console.log("onSuccess", code)
            setStarted(true)
            dispatch(
                logInWithSocial(
                    code, 
                    "google-oauth2", 
                    null
                )
            )
        }
    }, [code])

    const login = useGoogleLogin({
        flow: "auth-code",
        ux_mode: "redirect",
        redirect_uri: "https://musicboard.app/",
        state: encodeURIComponent(`auth:google;type:${isSignIn ? "sign-in" : "sign-up"};redirect:${location.pathname}`),
        scope: "email profile",
    });
    /*
    const login = useGoogleLogin({
        callback: (response) => {
            console.log("callback", JSON.stringify(response))
        },
        error_callback: (error) => {
            console.error("error_callback", error)
        },
        onNonOAuthError: (error) => {
            console.error("onNonOAuthError", error)
        },
        onSuccess: tokenResponse => {
            console.log("onSuccess", tokenResponse)
            setStarted(true)
            dispatch(
                logInWithSocial(
                    tokenResponse.access_token, 
                    "google-oauth2", 
                    null
                )
            )
        },
        onError: (error) => {
            console.error("onError", error)
            openAlert({
                p: "Log in failed. Try again or contact us if the issue persists. Error: " + error.error,
                style: "red",
                timer: 3500,
            })
        }
    });
    */

    return (
        <div className={`SignInBtn-wrapper ${className}`}>
            <Button
            type="secondary-two"
            text={isSignIn ? "Sign in with Google" : "Sign up with Google"}
            className={`col-1`}
            onClicked={() => {
                login()
            }}
            disabled={logInStatus === status.BEGIN}
            loading={logInStatus === status.BEGIN && started}
            height={41}
            />

            <div className='SignInBtn-icon flex-center' style={{ width: 24, height: 24 }}>
                <Icon icon={"google-login"} size={20} />
            </div>
        </div>
    )
}
