import './SignInBtn.css'

import React, { useEffect, useState } from 'react'
import AppleLogin from 'react-apple-login';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { status } from '../../../../constants/action-types';

import { logInWithSocial } from '../../../../actions/auth-actions';

import { useAlert } from '../../../../hooks/alert/alert';

import Icon from '../../../../components/Icon';
import Button from '../../../../components/Button';

export default function SignInWithApple({ className, isSignIn, id_token, redirect }) {
    //General
    const dispatch = useDispatch()
    const location = useLocation()
    const { openAlert } = useAlert()

    //Data
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const logInStatus = useSelector(state => state.auth.logInStatus)

    //State
    const [started, setStarted] = useState(false)

    //Start login based on id_token
    useEffect(() => {
        if (id_token && typeof id_token === "string" && id_token !== "") {
            console.log("onSuccess", id_token)
            setStarted(true)
            dispatch(
                logInWithSocial(
                    id_token, 
                    "apple-id-web", 
                    null
                )
            )
        }
    }, [id_token])

    return (
        <AppleLogin
        clientId="com.musicboard.app.web"
        redirectURI={"https://musicboard.app/"}
        scope="email name"
        responseType="code id_token"
        responseMode="form_post"
        state={encodeURIComponent(`auth:apple;type:${isSignIn ? "sign-in" : "sign-up"};redirect:${location.pathname}`)}
        render={({ onClick, disabled }) => (
            <div className={`SignInBtn-wrapper ${className}`}>
                <Button
                type="secondary-two"
                text={isSignIn ? "Sign in with Apple" : "Sign up with Apple"}
                className={`col-1`}
                onClicked={onClick}
                disabled={disabled || logInStatus === status.BEGIN}
                loading={logInStatus === status.BEGIN && started}
                height={41}
                />

                <div className='SignInBtn-icon flex-center' style={{ width: 24, height: 24 }}>
                    <Icon icon={"apple-login"} size={24} />
                </div>
            </div>
        )}
        />
    )
}
