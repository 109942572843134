import styles from './footer.module.css'

import React, { Suspense, lazy } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { useTheme } from '../../hooks/theme'
import { Link } from 'react-router-dom'

const EmailSignupForm = lazy(() => import('../../components/EmailSignupForm'))

export default function Footer(props) {
    const {mode} = useTheme()
    
    const ratingsCount = useSelector(state => state.local.ratingsCount)

    const isSmallScreen = useMediaQuery({ maxWidth: 960 })

    return (
        <div className=''>
            <div className={`${styles.container}`}>
                <footer className={`${styles.footer}`}>
                    <div className={styles.row}>
                        <div className={styles.footerSection}>
                            <h5>Musicboard</h5>
                            <p className="margin-top-12">
                                <Link to="/">Home</Link>
                                <br />
                                <Link className={styles.pro} to="/pro?ref=Footer">Pro</Link>
                                <br />
                                <Link to="/contributors">Contribute</Link>
                                <br />
                                <Link to={"/about"}>About</Link>
                                <br />
                                <Link to={"/apps"}>Apps</Link>
                            </p>
                        </div>

                        <div className={styles.footerSection}>
                            <h5>{"Legal"}</h5>
                            <p className="margin-top-12">
                                <Link to="/terms">Terms of Use</Link>
                                <br />
                                <Link to="/privacy">Privacy Policy</Link>
                                <br />
                                <Link to="/guidelines">Community Guidelines</Link>
                            </p>
                        </div>

                        <div className={styles.footerSection}>
                            <h5>Company</h5>
                            <p className="margin-top-12">
                                <Link to="/contact">Contact</Link>
                                <br />
                                <Link to="/faq">FAQ</Link>
                                <br />
                                <Link to="/welcome">Welcome</Link>
                            </p>
                        </div>

                        <div className={`${styles.footerSection} ${styles.noLine}`}>
                            <h5>Subscribe to Our Newsletter</h5>
                            <p className="margin-top-12">
                                Stay up to date with the latest releases, news, and offers.
                            </p>

                            <Suspense fallback={<div />}>
                                <EmailSignupForm className="margin-top-16" minimized />
                            </Suspense>

                        </div>
                    </div>

                    <div className={`bottom-divider margin-top-32`} />

                    {!isSmallScreen &&
                        <div className={`${styles.legal} margin-top-32 flex-row justify-between`}>
                            <p className="col-1">2025 Dreamsands Inc. All rights reserved.</p>

                            <div className="flex-row justify-center">
                                <a target="_blank" href="https://instagram.com/musicboard.app">
                                    <div className={styles.social}>
                                        <div className={styles.instagram} />
                                    </div>
                                </a>
                                <a target="_blank" href="https://x.com/musicboardapp">
                                    <div className={styles.social}>
                                        <div className={styles.twitter} />
                                    </div>
                                </a>
                            </div>

                            <div className="col-1 flex-column justify-end align-end">
                                <div>
                                    <p>
                                        <a href="https://www.playwire.com/contact-direct-sales" rel="noopener">
                                            <img src="https://www.playwire.com/hubfs/Powered-by-Playwire-Badges/Ads-Powered-by-playwire-2021-standalone-small-white-300px.png" alt="Ads-Powered-by-playwire-2021-standalone-small-white-300px" width="140" height="39" loading="lazy" style={{width: "140px", marginLeft: "auto", marginRight: "auto", display: "block", height: "auto", maxWidth: "100%"}} />
                                        </a>
                                    </p>
                                    
                                    <p style={{textAlign: "center", fontSize: "12px"}}>
                                        <a href="https://www.playwire.com/contact-direct-sales" rel="noopener" target="_blank">Advertise on this site.</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    }

                    {isSmallScreen &&
                        <div className={`${styles.legal} margin-top-32 flex-center flex-column`}>
                            <div className="flex-row justify-center">
                                <a target="_blank" href="https://instagram.com/musicboardapp">
                                    <div className={styles.social}>
                                        <div className={styles.instagram} />
                                    </div>
                                </a>
                                <a target="_blank" href="https://x.com/musicboardapp">
                                    <div className={styles.social}>
                                        <div className={styles.twitter} />
                                    </div>
                                </a>
                            </div>

                            <p className="col-1 text-center margin-top-16">2025 Dreamsands Inc. All rights reserved.</p>

                            <div className="col-1">
                                {ratingsCount && //Formats the number to comma seperated
                                    <p className="text-center">{ratingsCount && ratingsCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ratings recorded</p>
                                }
                            </div>
                        </div>
                    }
                    
                </footer>
            </div>
        </div>
    )
}
