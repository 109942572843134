import styles from './signuppopup.module.css'

import React, { useState, useEffect, useRef } from 'react'

import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import Icon from '../../components/Icon'
import { useTheme } from '../../hooks/theme'
import { useDispatch, useSelector } from 'react-redux'
import { signUp, userExists } from '../../actions/auth-actions'
import { usePopup } from '../../hooks/popup/popup'
import { status } from '../../constants/action-types'
import Carousel from '../../components/Carousel'
import { isValidEmail } from '../../utils/validators'
import Checkbox from '../../components/Checkbox'
import { signUpForNewsletter } from '../../actions/news-actions'
import { Link, useHistory } from 'react-router-dom'
import IconButton from '../../components/IconButton'

import SignInWithApple from './components/SignInWithApple'
import SignInWithGoogle from './components/SignInWithGoogle'
import Title from '../../shared/Title'
import { useMediaQuery } from 'react-responsive'
import { stripeLifetimeOfferLink } from '../../config'
import { useTrack } from '../../hooks/tracking'

export default function SignUpPopup({ options }) {
    //General
    const track = useTrack()
    const history = useHistory()
    const dispatch = useDispatch()
    const { openPopup } = usePopup()

    //Responsive
    const isMobile = useMediaQuery({ maxWidth: 630 })
    const isMinimum = useMediaQuery({ maxWidth: 490 })

    //Data
    const myUid = useSelector(state => state.auth.uid)
    const myEmail = useSelector(state => state.me.email)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const completed_onboarding = useSelector(state => state.me.completed_onboarding)

    const token = useSelector(state => state.auth.token)
    const accessToken = useSelector(state => state.auth.accessToken)
    const logInStatus = useSelector(state => state.auth.logInStatus)
    const errorMessage = useSelector(state => state.auth.errorMessage)

    //State
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [error, setError] = useState(null)

    //Set error message
    const firstUpdateError = useRef(true)
    useEffect(() => {
        if(firstUpdateError.current) {
            firstUpdateError.current = false;
            return;
        }

        if (errorMessage && logInStatus === status.ERROR) {
            setError(errorMessage)
        }
    }, [ errorMessage, logInStatus ])

    //Close popup when done
    const firstUpdate = useRef(true)
    useEffect(() => {
        if(firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if(logInStatus === status.SUCCESS && isLoggedIn && (token || accessToken)) {
            track("Sign up", { fb_registration_method: "Email" });

            if (!completed_onboarding) {
                if (options?.isStackSocial) {
                    window.location.href = `${stripeLifetimeOfferLink}?prefilled_email=${myEmail}${options?.promoCode ? `&prefilled_promo_code=${options?.promoCode}` : ""}&client_reference_id=${myUid}`
                } else {
                    history.push("/onboarding")
                }
            } else if (options?.redirect) {
                history.push(options?.redirect)
            } else {
                openPopup(null)
            }

        }

    }, [ logInStatus, isLoggedIn ])

    //Helper functions
    function sendRequest() {
        track("Started sign up request");

        if(name.trim() === "" || password.trim() === "" || email.trim() === "") {
            setError("All fields have to be filled.")
        } else if (password.length < 8) {
            setError("Password has to be at least 8 characters.")
        } else if (!isValidEmail(email)) {
            setError("Enter a valid email address.")
        } else {
            dispatch(
                signUp(name, email, password)
            )
        }
    }

    return (
        <div className={styles.wrapper}>
            <IconButton
            className={styles.closeButton}
            type="transparent-bright"
            icon="close"
            onClick={() => {
                openPopup(null)
            }}
            />
            
            <h4 className={`black text-2xl ${styles.title}`}>Sign up to{isMinimum ? <br /> : " "}Musicboard</h4>

            <SignInWithApple 
            className="margin-top-48" 
            isSignIn={false}
            id_token={options?.auth === "apple" ? options?.id_token : null}
            redirect={options?.redirect}
            />

            <SignInWithGoogle 
            className="margin-top-16" 
            isSignIn={false}
            code={options?.auth === "google" ? options?.code : null}
            redirect={options?.redirect}
            />

            <div className='flex-row margin-top-32'>
                <div style={{ height: 1, flex: '1 1', backgroundColor: 'var(--darkLightGrey)' }} />

                <h6 className="margin-left-16 margin-right-16 highDarkGrey">OR</h6>
                
                <div style={{ height: 1, flex: '1 1', backgroundColor: 'var(--darkLightGrey)' }} />
            </div>

            <TextInput
            lighterStyle
            placeholder="John Doe"
            className="margin-top-32"
            autoComplete="new-password"
            label="Your name"
            name="name"
            type="text"
            autoCapitalize={"off"}
            text={name}
            onTextChange={(value) => {
                setName(value)
                setError(null)
            }}
            onEnterClicked={sendRequest}
            />

            <TextInput
            lighterStyle
            placeholder="johndoe@gmail.com"
            className="margin-top-16"
            label="Email address"
            name="email"
            type="email"
            autoCapitalize={"off"}
            text={email}
            onTextChange={(value) => {
                setEmail(value)
                setError(null)
            }}
            onEnterClicked={sendRequest}
            />

            <TextInput
            lighterStyle
            placeholder="********"
            autoComplete="new-password"
            className="margin-top-16"
            label="Password"
            name="password"
            type="password"
            autoCapitalize={"off"}
            text={password}
            onTextChange={(value) => {
                setPassword(value)
                setError(null)
            }}
            onEnterClicked={sendRequest}
            />

            {error &&
                <div className={`flex-row margin-top-16 ${styles.errorWrapper}`}>
                    <Icon icon="warning" size={18} color={"var(--red)"} />
                    <p className="margin-left-8 medium red">{error}</p>
                </div>
            }

            <div className="flex-row align-top margin-top-24">
                <Icon size={24} color="var(--highDarkGrey)" icon="info" />

                <div className="col-1 grid">
                    <p className={`margin-left-8 margin-top-2 highDarkGrey`}>
                        By continuing, you verify that you are at least 16 years old, and agree to both our{" "}
                        <Link to="/terms" className={styles.privacyLink}>Terms of Use</Link>{" "}and{" "}
                        <Link to="/privacy" className={styles.privacyLink}>Privacy Policy</Link>.
                    </p>
                </div>
            </div>

            <Button
            className="margin-top-32"
            text="Create Account"
            //disabled={!acceptedTerms || email.trim() === "" || username.trim() === "" || password.trim() === ""}
            loading={logInStatus === status.BEGIN}
            onClicked={sendRequest}
            />

            <div className={`flex-row flex-center margin-top-32 ${isMobile && "margin-top-32 margin-bottom-16"}`}>
                <p 
                className={`cursor ${styles.forgot}`}
                onClick={() => openPopup("Login")}
                >
                    Already have an account? Log in
                </p>
            </div>
        </div>
    )
}
