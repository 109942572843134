import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { usePopup } from '../../hooks/popup/popup';

export default function AuthWrapper({ children }) {
    //General
    const location = useLocation()
    const { openPopup } = usePopup()

    //Params
    const state = new URLSearchParams(location.search).get("state")
    const code = new URLSearchParams(location.search).get("code")
    const id_token = new URLSearchParams(location.search).get("id_token")

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

    //Variables
    const decodedState = decodeURIComponent(state)
    const auth = decodedState.split(";")[0]?.replace("auth:", "")
    const type = decodedState.split(";")[1]?.replace("type:", "")
    const redirect = decodedState.split(";")[2]?.replace("redirect:", "")

    //Open popup based on params
    useEffect(() => {
        setTimeout(() => {
            if (!isLoggedIn && auth && type) {
                if (type === "sign-in") {
                    openPopup("Login", { code, id_token, auth, redirect })
                } else if (type === "sign-up") {
                    openPopup("SignUp", { code, id_token, auth, redirect })
                }
            }
        }, 200)
    }, [])
    return children
}
