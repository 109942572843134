import styles from './minifooter.module.css'
import React from 'react'
import { Link } from 'react-router-dom'

export default function MiniFooter({
    className,
    style
}) {
    const items = [
        {
            label: "About",
            href: "/about",
        },
        {
            label: "News",
            href: "/news",
        },
        {
            label: "Pro",
            href: "/pro?ref=MiniFooter",
            className: styles.pro
        },
        {
            label: "Apps",
            href: "/apps",
        },
        {
            label: "FAQ",
            href: "/faq",
        },
        {
            label: "Contact",
            href: "/contact",
        },
        {
            label: "Help",
            href: "/welcome",
        },
        {
            label: "X",
            href: "https://x.com/musicboardapp",
            newTab: true,
        },
        {
            label: "Instagram",
            href: "https://instagram.com/musicboard.app",
            newTab: true,
        },
        {
            label: "Terms of Use",
            href: "/terms",
        },
        {
            label: "Privacy Policy",
            href: "/privacy",
        },
        {
            label: "Contributor Guidelines",
            href: "/contributors",
        },
    ]

    return (
        <div className={`margin-top-16 ${className}`} style={{ ...style }}>
            <div className={styles.line} />

            <p className={`margin-top-16 ${styles.text}`}>
                {items.map((item, index) => (
                    <span key={`minifooter-item-${index}`}>
                        {index !== 0 && " "}
                        {item.newTab ?
                            <a href={item.href} target="_blank" rel="noopener noreferrer" className={item.className}>
                                {item.label}
                            </a>
                        :
                            <Link to={item.href} className={item.className}>
                                {item.label}
                            </Link>
                        }
                        {index !== items.length-1 && " •"}
                    </span>
                ))}
            </p>
            
            <p className={`margin-top-8 ${styles.text}`}>
                2025 Dreamsands Inc. All rights reserved.
            </p>
        </div>
    )
}
