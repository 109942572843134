import styles from './review.module.css'

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ClampLines from 'react-clamp-lines'
import { useMediaQuery } from 'react-responsive'
import moment, { relativeTimeRounding } from 'moment'

import { status, types } from '../../constants/action-types'
import { recordTypeEnums, themeEnums } from '../../constants/enums'

import { getComments } from '../../actions/comments-actions'
import { getUsersReviews } from '../../actions/users-actions'
import { deleteLike, likeContent } from '../../actions/likes-actions'
import { fetchReview, fetchReviewFromSlug, translateReview, detectReviewLanguage } from '../../actions/reviews-actions'
import { fetchAlbum, fetchArtist, fetchTrack, getAlbumReviews, getArtistReviews, getTrackReviews } from '../../actions/content-actions'

import { createBreadcrumbSchema, createReviewDiscussionForumSchema, createReviewSchema } from '../../data/structured-data'

import { useTheme } from '../../hooks/theme'
import { usePopup } from '../../hooks/popup/popup'
import { useStatusHandler } from '../../hooks/status-handler'

import OneLineLoader from '../../loaders/OneLineLoader'

import DefaultHelmet from '../../shared/DefaultHelmet'
import ColorOverwrite from '../../shared/ColorOverwrite'
import StructuredData from '../../shared/StructuredData'

import { displayRecordType } from '../../utils/record-type-utils'
import { addEndingS, checkNull, checkNullNumber, isLastS } from '../../utils/format-text'

import Icon from '../../components/Icon'
import Stars from '../../components/Stars'
import Button from '../../components/Button'
import Heading from '../../components/Heading'
import ProIcon from '../../components/ProIcon'
import Tooltip from '../../components/Tooltip'
import SignUpAd from '../../components/SignUpAd'
import AlbumCover from '../../components/AlbumCover'
import MiniFooter from '../../components/MiniFooter'
import ReviewItem from '../../components/ReviewItem'
import OptionsMenu from '../../components/OptionsMenu'
import AdComponent from '../../components/AdComponent'
import VerifiedIcon from '../../components/VerifiedIcon'
import ShareElement from '../../components/ShareElement'
import ParseUserText from '../../components/ParseUserText'
import ProfilePicture from '../../components/ProfilePicture'
import FullBackground from '../../components/FullBackground'
import ReportDropdown from '../../components/ReportDropdown'
import StickerSection from '../../components/StickerSection'
import StickyScrolling from '../../components/StickyScrolling'
import UserProfilePicture from '../../components/UserProfilePicture'
import ContentOptionsTooltip from '../../components/ContentOptionsTooltip'

import NotFound from '../NotFound'

import ReviewCommentSection from './components/ReviewCommentSection'
import PlayContentButton from '../../components/PlayContentButton'
import { useTrack } from '../../hooks/tracking'

export default function Review(props) {
    //General
    const { openPopup } = usePopup()
    const { colors } = useTheme()
    const { uid, usernameSlug, trackSlug, albumSlug, artistSlug, integerSlug } = useParams()
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const track = useTrack()

    //Params
    const fromRedirect = new URLSearchParams(location.search).get("rd") !== null

    //Responsive
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isTablet = useMediaQuery({ maxWidth: 768 })

    //Variable
    const urlSlug = useMemo(() => 
        !uid && trackSlug ?
            `/${usernameSlug}/review/track/${trackSlug}/${albumSlug}/${artistSlug}${integerSlug ? `/${integerSlug}/` : "/"}`
        : !uid && albumSlug ?
            `/${usernameSlug}/review/album/${albumSlug}/${artistSlug}${integerSlug ? `/${integerSlug}/` : "/"}`
        : !uid && artistSlug ?
            `/${usernameSlug}/review/artist/${artistSlug}${integerSlug ? `/${integerSlug}/` : "/"}`
        : null
    , [ uid, usernameSlug, trackSlug, albumSlug, artistSlug, integerSlug ])

    //Prefetched review uid
    const urlSlugConnectionUid = useSelector(state => !uid && artistSlug ? state.local.reviewUrlSlugResult[urlSlug] : null)

    //Support to update the review uid if using slugs
    const [reviewUid, setReviewUid] = useState(urlSlugConnectionUid || uid)

    //Auth
    const isModerator = useSelector(state => state.me.is_moderator)

    //likeObj
    const deleteLikeContentUid = useSelector(state => state.likes.deleteLikeContentUid)
    const deleteLikeStatus = useSelector(state => state.likes.deleteLikeStatus)
    const myLikes = useSelector(state => state.me.likes);
    const cachedLikes = useSelector(state => state.me.cachedLikes);
    const likes = [ ...myLikes, ...cachedLikes ];
    const likeIndex = likes.findIndex(like => like.content_ct === "review" && like.content_uid === reviewUid);
    const likeObj = likeIndex !== -1 ? likes[likeIndex] : null;

    //Data
    const review = useSelector(state => state.reviews.reviews[reviewUid])
    const comments = useSelector(state => state.comments.reviews[reviewUid])
    const fetchReviewStatus = useSelector(state => state.reviews.fetchReviewStatus)
    const lastFetchedReviewUid = useSelector(state => state.reviews.lastFetchedReviewUid)
    const errorMessage = useSelector(state => state.reviews.errorMessage)

    const content = useSelector(state => 
        review && review.uid ?
            review.rating.content.type === "album" ?
                state.albums.albums[review.rating.content.id]
            : review.rating.content.type === "track" ?
                state.tracks.tracks[review.rating.content.id]
            : review.rating.content.type === "artist" ?
                state.artists.artists[review.rating.content.id]
            : 
                null
        :
            null
    )

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const myUid = useSelector(state => state.auth.uid);
    const isMe = review && review.creator && review.creator.uid === myUid;
    
    //blockedBy
    const blockedByObj = useSelector(state => 
        state.me.blockedBy && state.me.blockedBy.find(block => review && block.creator === review?.creator?.uid)
    )

    //Data when rating is fetched
    const userReviews = useSelector(state =>
        review && state.users.users[review.creator.username] && state.users.users[review.creator.username].reviews ? 
            state.users.users[review.creator.username].reviews
        : null
    )
    const contentReviews = useSelector(state =>
        review && 
        review.uid && 
        (
            review.rating.content.type === "album" || 
            review.rating.content.type === "track" || 
            review.rating.content.type === "artist"
        ) &&
        state[`${review.rating.content.type}s`][`${review.rating.content.type}s`][review.rating.content.id] && state[`${review.rating.content.type}s`][`${review.rating.content.type}s`][review.rating.content.id].contentReviews ? 
            state[`${review.rating.content.type}s`][`${review.rating.content.type}s`][review.rating.content.id].contentReviews
        : null
    )
    
    //Translation data
    const translatedReview = useSelector(state => state.reviews.translatedReviews[reviewUid])
    const translateReviewStatus = useSelector(state => state.reviews.translateReviewStatus)
    const primaryLanguage = useSelector(state => state.me.primary_language)
    const supportedLanguages = useSelector(state => state.auth.healthCheck.supported_languages)
    
    //Translation variables
    const reviewLangDisplay = (
        supportedLanguages && review && supportedLanguages.find((i) => i.code === review.detected_language) ? 
            supportedLanguages.find((i) => i.code === review.detected_language).lang 
        : 
            null
    )
    const primaryLangDisplay = (
        supportedLanguages && primaryLanguage && supportedLanguages.find((i) => i.code === primaryLanguage) ? 
            supportedLanguages.find((i) => i.code === primaryLanguage).lang 
        : 
            null
    )
    
    //State
    const [showTranslation, setShowTranslation] = useState(false)
    const [initWait, setInitWait] = useState(true)

    //Refs
    const hasFetched = useRef(false)

    // Get data
    useEffect(() => {
        //Set init wait to hide error
        setInitWait(true)
        setTimeout(() => {
            setInitWait(false)
        }, 75)

        //Reset the hasFetched ref when we change the review
        hasFetched.current = false

        //Reset the state uid
        if (urlSlug) {
            if (urlSlugConnectionUid) { setReviewUid(urlSlugConnectionUid) } else { setReviewUid(null) }
        } else {
            setReviewUid(uid)
        }

        if (urlSlug) {
            if (!fromRedirect || !(review && review.uid)) {
                dispatch( 
                    fetchReviewFromSlug(urlSlug) 
                )
            }
        } else {
            dispatch( 
                fetchReview(uid) 
            )
        }

    }, [ uid, usernameSlug, trackSlug, albumSlug, artistSlug, integerSlug ])

    //Potentially update the reviewUid if our backend return a different
    useStatusHandler({
        effectStatus: fetchReviewStatus,
        successCallback: () => {
            if (lastFetchedReviewUid && lastFetchedReviewUid !== reviewUid) {
                setReviewUid(lastFetchedReviewUid)
            }
        }
    })

    //Get data when rating is fetched
    //To only get it once, we use the ref to keep track.
    //Otherwise we would refetch all this when liking and updating the review's like_count
    useEffect(() => {
        if (review && review.rating && !hasFetched.current) {
            //Set ref
            hasFetched.current = true;

            //If we have the ID, replace url with the URL slug
            if (uid && !artistSlug) {
                const locationSearchList = location.search ? location.search.replace("?", "").split("&") : []
                if (locationSearchList.indexOf("rd") === -1) {
                    locationSearchList.push("rd")
                }
                history.replace(`${review.url_slug}${locationSearchList.length > 0 ? "?" : ""}${locationSearchList.join("&")}`)
            } 
            
            //Get comments
            dispatch( 
                getComments(review.uid, 'review') 
            )

            //Get user reviews
            dispatch( 
                getUsersReviews({
                    username: review.creator.username,
                    uid: review.creator.uid,
                    limit: 3
                })
            )

            //Get content reviews
            if(review.rating.content.type === "album") {
                dispatch(
                    getAlbumReviews({
                        id: review.rating.content.id,
                        order_by: "",
                        scored: true,
                        limit: 3,
                        type: types.GET_ALBUMS_CONTENT_REVIEWS,
                    })
                )
            }
            if(review.rating.content.type === "track") {
                dispatch(
                    getTrackReviews({
                        id: review.rating.content.id,
                        order_by: "",
                        scored: true,
                        limit: 3,
                        type: types.GET_TRACKS_CONTENT_REVIEWS,
                    })
                )
            }
            if(review.rating.content.type === "artist") {
                dispatch(
                    getArtistReviews({
                        id: review.rating.content.id,
                        order_by: "",
                        scored: true,
                        limit: 3,
                        type: types.GET_ARTISTS_CONTENT_REVIEWS,
                    })
                )
            }

            //Get language detection if missing
            if(!review.detected_language) {
                dispatch(
                    detectReviewLanguage(review.uid)
                )
            }
        }

    }, [ review && review.uid ])

    //Get album data from review
    useEffect(() => {
        if (review && review.uid) {
            if (review.rating.content.type === "album") {
                dispatch(
                    fetchAlbum(review.rating.content.id)
                )
            } else if (review.rating.content.type === "track") {
                dispatch(
                    fetchTrack(review.rating.content.id)
                )
            } else {
                dispatch(
                    fetchArtist(review.rating.content.id)
                )
            }
        }
    }, [review && review.uid])

    //Scroll on param
    const scrollCommentsParam = new URLSearchParams(location.search).get("comments")
    const element = document.getElementById("comment-title")
    useEffect(() => {
        if (review && element && scrollCommentsParam === "true") {
            element.scrollIntoView({ behavior: "smooth" })
        }
    }, [ element ])

    //Variables
    const contentType = review && review.rating && review.rating.content.type
    const isSuggested = (contentType === "suggested_album" || contentType === "suggested_artist" || contentType === "suggested_track")
    const formattedContentType = isSuggested ? contentType.substr(10, 100) : contentType
    const contentTitle = review && review.rating && (
        (review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") ? 
            review.rating.content.name 
        : 
            review.rating.content.title
    )
    const detailedContentTitle = review && review.rating && (
        (review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") ? 
            review.rating.content.name 
        : 
            `${review.rating.content.title} by ${review.rating.content.artist.name}`
    )
    const contentImage = review && review.rating && (
        review.rating.content.type === "artist" ? 
            (isTablet ? review.rating.content.picture_medium : review.rating.content.picture_large) || review.rating.content.picture
        : review.rating.content.type === "album" ? 
            (isTablet ? review.rating.content.cover_medium : review.rating.content.cover_large) || review.rating.content.cover
        : review.rating.content.type === "track" ? 
            (isTablet ? review.rating.content.album.cover_medium : review.rating.content.album.cover_large) || review.rating.content.album.cover
        : review.rating.content.type === "suggested_artist" ?
            review.rating.content.picture ?
                review.rating.content.picture
            :
                review.rating.content.picture_url
        : review.rating.content.type === "suggested_album" ?
            review.rating.content.cover ?
                review.rating.content.cover
            :
                review.rating.content.cover_url
        : review.rating.content.type === "suggested_track" ?
            review.rating.content.album ? 
                review.rating.content.album.cover ?
                    review.rating.content.album.cover
                :
                    review.rating.content.album.cover_url
            :
                review.rating.content.suggested_album.cover ?
                    review.rating.content.suggested_album.cover
                :
                    review.rating.content.suggested_album.cover_url
        : null
    )
    const href = review && review.rating && (
        review.rating.content.type === "artist" ?
            review.rating.content.url_slug
        : review.rating.content.type === "track" ?
            review.rating.content.url_slug
        : review.rating.content.type === "album" ?
            review.rating.content.url_slug
        : null
    )

    //Render loading or 404
    if(!review || !review.uid || !review.rating) {
        if (!initWait && (fetchReviewStatus === status.SUCCESS || fetchReviewStatus === status.ERROR)) {
            return (
                <NotFound />
            )
        }
        return (
            <div className="full-height">
                <div className="full-center">
                    <Icon className="margin-auto" icon="loading" color={colors.darkLightGrey} size={32} />
                </div>
            </div>
        )
    }

    if (blockedByObj) {
        return (
            <NotFound />
        )
    }

    const filteredUserReviews = userReviews ?
        userReviews.data
        .filter((userReview => userReview.uid !== review.uid))
        .filter((_, i) => i < 2)
    : []

    const filteredContentReviews = contentReviews ?
        contentReviews.data
        .filter((userReview => userReview.uid !== review.uid))
        .filter((_, i) => i < 2)
    : []

    //Render
    return (
        <div>
            <DefaultHelmet
            title={`Review of ${contentTitle} by ${review.creator.username}${review.url_slug.match(/\/\d+\/$/) ? ` - ${review.url_slug.match(/\/(\d+)\/$/)[1]}` : ''} - Musicboard`}
            description={
                review.description !== "" ?
                    `Review of ${detailedContentTitle}. ${review.creator.name !== "" ? review.creator.name : review.creator.username} rated the ${review.rating.content.type === "album" ? recordTypeEnums[review.rating.content.record_type] : review.rating.content.type} ${(review.rating.rating / 2).toFixed(1)} / 5 stars. ${review.description.substr(0, 300)}${review.description.length >= 300 ? '...' : '.'}`
                :
                    `${review.creator.name !== "" ? review.creator.name : review.creator.username} gave ${contentTitle} a rating of ${(review.rating.rating / 2).toFixed(1)} / 5 stars on Musicboard. Read the entire review of ${contentTitle} on Musicboard.`
            }
            canonical={`https://musicboard.app${review.url_slug}`}
            ogImage={contentImage}
            ogImageAlt={contentTitle}
            ogImageWidth={500}
            ogImageHeight={500}
            twitterImage={contentImage}
            twitterImageWidth={500}
            twitterImageHeight={500}
            labelOne={{ label: "Rating", data: `${(review.rating.rating / 2).toFixed(1)} out of 5` }}
			labelTwo={{ label: "Published On", data: moment(review.created_at).format("DD MMM YYYY") }}
			deepLink={`musicboard://review/${review.uid}`}
            headingOne={`Review of ${contentTitle}`}
            headingTwo={`A Music Review by ${review.creator.username} on Musicboard`}
            />

            {(review && review.uid && !isSuggested) && review.rating.rating !== null ?
                <StructuredData
                key={`Review-Data-${review.uid}`}
                id={`Review-Data-${review.uid}`}
                data={createReviewSchema(review)}
                />
            : null}

            {comments && comments.data && (comments.data.length > 0 || comments.status === status.SUCCESS) &&
                <StructuredData
                key={`Review-DiscussionForum-Data-${review.uid}`}
                id={`Review-DiscussionForum-Data-${review.uid}`}
                data={createReviewDiscussionForumSchema(review, { count: comments.count, results: comments.data })}
                />
            }

            <StructuredData
            key={`Review-Breadcrumb-Data-${review.uid}`}
            id={`Review-Breadcrumb-Data-${review.uid}`}
            data={createBreadcrumbSchema([
                { "name": "Musicboard", "item": "https://musicboard.app/" },
                { "name": "Reviews", "item": "https://musicboard.app/reviews/" },
                { "name": review.creator.username, "item": `https://musicboard.app/${review.creator.username}/` },
                { "name": review.title !== "" ? review.title : `Review of ${contentTitle}`, "item": `https://musicboard.app${review.url_slug}` }
            ])}
            />

            {review.creator.is_pro && review.creator.tier === 1 && review.creator.color_theme !== null && review.creator.color_theme !== undefined && review.creator.use_theme_on_content &&
                <ColorOverwrite
                colorTheme={themeEnums[review.creator.color_theme]}
                styleName={"review-color-overwrite"}
                setBackground
                />
            }

            <div className='review-color-overwrite'>
                <div style={{ backgroundColor: 'var(--lightestGrey)', position: 'relative', zIndex: 1 }}>
                    {review.creator.is_pro && review.creator.tier === 1 && review.rating.background && review.rating.background.background_large &&
                        <FullBackground
                        image={review.rating.background.background_original?.includes(".gif") ? review.rating.background.background_original : review.rating.background.background_large}
                        placeholder={review.rating.background.background_small}
                        marginHeight={isTablet ? 330 : review.rating.content.type === "artist" ? 200 : 230}
                        altText={`Review of ${contentTitle} by ${review.creator.username}`}
                        />
                    }

                    <div className="page-container">
                        {!isTablet &&
                            <div className="padding-top-64">
                                <div className="flex-row align-end">
                                    <div className={`${styles.center} content-options-wrapper`}>
                                        <Link to={href} className={isSuggested ? styles.disabledLink : ""}>
                                            {(review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") &&
                                                <ProfilePicture
                                                clickable
                                                size={220}
                                                profilePicture={contentImage}
                                                altText={contentTitle}
                                                />
                                            }
                                            
                                            {!(review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") &&
                                                <AlbumCover
                                                clickable
                                                size={220}
                                                albumCover={contentImage}
                                                altText={contentTitle}
                                                />
                                            }
                                        </Link>

                                        {!isSuggested &&
                                            <div className={styles.alignCenter}>
                                                <ContentOptionsTooltip content={review.rating.content} appendId={`review`} />
                                            </div>
                                        }
                                    </div>

                                    <div className="col-2 margin-left-24 padding-bottom-16 border-bottom">
                                        <div className="flex-row">
                                            <Link to={href} className={isSuggested ? styles.disabledLink : ""}>
                                                <h3 className={`text-xl ${styles.a}`} style={contentTitle.length > 46 ? { fontSize: 24, lineHeight: '30px' } : contentTitle.length > 38 ? { fontSize: 28, lineHeight: '34px' } : {}}>{contentTitle}</h3>
                                            </Link>
                                        </div>

                                        {(review.rating.content.type === "album" || review.rating.content.type === "suggested_album") &&
                                        <>
                                            {content && content.record_type && 
                                                <div className="flex-row margin-top-12">
                                                    {content.record_type &&
                                                        <p className="highDarkGrey margin-right-12">{displayRecordType(review.rating.content.record_type)}</p>
                                                    }

                                                    {content.record_type &&
                                                        <div className="dot margin-right-12" />
                                                    }

                                                    {content.release_date &&
                                                        <p className="highDarkGrey margin-right-12">{content.release_date}</p>
                                                    }

                                                    {content.release_date &&
                                                        <div className="dot margin-right-12" />
                                                    }

                                                    {content.nb_tracks &&
                                                        <p className={`highDarkGrey`}>{content.nb_tracks} Tracks</p>
                                                    }
                                                </div>
                                            }

                                            {!(content && content.record_type) && 
                                                <OneLineLoader className="margin-top-12" />
                                            }
                                        </>
                                        }

                                        {(review.rating.content.type === "track" || review.rating.content.type === "suggested_track") &&
                                        <>
                                            {content && content.album && content.album.id && 
                                                <div className="flex-row margin-top-12">
                                                    <Link to={content.album.url_slug} className={isSuggested ? styles.disabledLink : ""}>
                                                        <p className="black margin-right-12">{content.album.title}</p>
                                                    </Link>

                                                    <div className="dot margin-right-12" />
                                                    
                                                    <p className="highDarkGrey margin-right-12">{`Track`}</p>

                                                    {content.album.release_date &&
                                                        <div className="dot margin-right-12" />
                                                    }
                                                    {content.album.release_date &&
                                                        <p className={`highDarkGrey`}>{content.album.release_date}</p>
                                                    }
                                                </div>
                                            }
                                            
                                            {!(content && content.album && content.album.id) &&
                                                <OneLineLoader className="margin-top-12" />
                                            }
                                        </>
                                        }

                                        {(review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") &&
                                            <p className="highDarkGrey margin-top-12">{`Artist`}</p>
                                        }

                                        {!(review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") &&
                                            <div className="flex-row margin-top-16">
                                                <Link to={review.rating.content.type !== "artist" ? review.rating.content.artist.url_slug : ""} className={review.rating.content.suggested_artist ? styles.disabledLink : ""}>
                                                    <ProfilePicture 
                                                    size={32} 
                                                    clickable 
                                                    profilePicture={
                                                        review.rating.content.artist ? 
                                                            review.rating.content.artist.picture_small || review.rating.content.artist.picture 
                                                        : review.rating.content.suggested_artist.picture ? 
                                                            review.rating.content.suggested_artist.picture 
                                                        : 
                                                            review.rating.content.suggested_artist.picture_url
                                                    } 
                                                    altText={
                                                        review.rating.content.artist ? 
                                                            review.rating.content.artist.name 
                                                        : 
                                                            review.rating.content.suggested_artist.name
                                                    }
                                                    />
                                                </Link>
                                                
                                                <Link to={review.rating.content.type !== "artist" ? review.rating.content.artist.url_slug : ""} className={review.rating.content.suggested_artist ? styles.disabledLink : ""}>
                                                    <p className="medium margin-left-12" style={{ fontSize: 16 }}>
                                                        {review.rating.content.artist ? 
                                                            review.rating.content.artist.name 
                                                        : 
                                                            review.rating.content.suggested_artist.name
                                                        }
                                                    </p>
                                                </Link>
                                            </div>
                                        }
                                    </div>

                                    <div className={`${isSmallScreen ? "" : "col-1"} flex-row justify-end border-bottom padding-bottom-16 padding-left-16`}>
                                        <PlayContentButton 
                                        size={52} 
                                        content={review.rating.content} 
                                        appendKey={`Review-${review.uid}`}
                                        style={{ marginRight: -5 }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="section-handler">
                            <div className="section-2 section-margin ">
                                <div className="flex-row align-top margin-top-64">
                                    {!isTablet &&
                                        <Link className="margin-right-24" to={`/${review.creator.username}`}> 
                                            <UserProfilePicture
                                            size={60}
                                            profilePicture={review.creator.profile_picture?.includes(".gif") ? review.creator.profile_picture : review.creator.profile_picture_small || review.creator.profile_picture}
                                            profileFrame={review.creator.is_pro && review.creator.tier === 1 ? review.creator.profile_frame : null}
                                            clickable
                                            altText={review.creator.name !== "" ? review.creator.name : review.creator.username}
                                            />
                                        </Link>
                                    }

                                    <div className="col-1">
                                        <div className="flex-row">
                                            <div className="col-1">
                                                <div className="flex-row justify-between">
                                                    <div className="flex-row">
                                                        {isTablet &&
                                                            <Link className="margin-right-8" to={`/${review.creator.username}`}> 
                                                                <UserProfilePicture
                                                                size={24}
                                                                profilePicture={review.creator.profile_picture?.includes(".gif") ? review.creator.profile_picture : review.creator.profile_picture_small || review.creator.profile_picture}
                                                                profileFrame={review.creator.is_pro && review.creator.tier === 1 ? review.creator.profile_frame : null}
                                                                clickable
                                                                altText={review.creator.name !== "" ? review.creator.name : review.creator.username}
                                                                />
                                                            </Link>
                                                        }

                                                        <div className="grid">
                                                            <h5 className={`one-line grey`} style={{ fontSize: isTablet ? 15 : 16, }}>
                                                                {!isTablet &&
                                                                    <span style={{ fontWeight: 'normal' }} className="grey">{"Review by "}</span>
                                                                }

                                                                <Link to={`/${review.creator.username}`} className={styles.a}>
                                                                    {review.creator.username}
                                                                </Link>
                                                            </h5>
                                                        </div>

                                                        {(review.creator.is_pro || review.creator.is_moderator || review.creator.contributor_tier) &&
                                                            <ProIcon 
                                                            size={"small"} 
                                                            className="margin-left-8" 
                                                            is_pro={review.creator.is_pro}
                                                            is_moderator={review.creator.is_moderator}
                                                            contributor_tier={review.creator.contributor_tier}
                                                            />
                                                        }

                                                        {review.creator.is_verified &&
                                                            <VerifiedIcon size={12} className="margin-left-8" style={{marginTop: 1}} />
                                                        }
                                                    </div>

                                                    {!isTablet && 
                                                        <ReportDropdown content={review} type="review" />
                                                    }
                                                </div>

                                                {isTablet &&
                                                    <Link to={href} className={isSuggested ? styles.disabledLink : ""}>
                                                        <ClampLines
                                                            text={contentTitle}
                                                            id={`reviewTabletTitle`}
                                                            lines={1}
                                                            ellipsis="..."
                                                            className={`margin-top-16 ${styles.a}`}
                                                            innerElement="h4"
                                                            buttons={false}
                                                        />
                                                    </Link>
                                                }

                                                {(Boolean(review.rating.rating) || review.rating.first_listen === false) &&
                                                    <div className={`flex-row ${isTablet ? "margin-top-12" : "margin-top-4"}`}>
                                                        {review.rating.rating &&
                                                            <div id={`review-${review.uid}-stars-tip`} style={{ height: isTablet ? 20 : 24 }} className="margin-right-8 cursor-default">
                                                                <Stars iconSize={isTablet ? 20 : 24} rating={review.rating.rating} />
                                                            </div>
                                                        }
                                                        
                                                        {review.rating.first_listen === false &&
                                                            <div 
                                                            id={`review-${review.uid}-first-listen-tip`} 
                                                            style={{ height: isTablet ? 20 : 24 }} 
                                                            className="margin-right-8 cursor"
                                                            onClick={() => openPopup("UpdateReview", { review: review })}
                                                            >
                                                                <Icon icon="sync" style={{ transform: "rotate(45deg)" }} size={isTablet ? 20 : 24} color={"var(--grey)"} />
                                                            </div>
                                                        }

                                                        {(review.private || review.private_locked) &&
                                                            <div 
                                                            id={`review-${review.uid}-private-tip`} 
                                                            style={{ height: isTablet ? 16 : 20 }} 
                                                            className="cursor"
                                                            onClick={() => openPopup("UpdateReview", { review: review })}
                                                            >
                                                                <Icon icon="lock" size={isTablet ? 16 : 20} color={"var(--grey)"} />
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                                <Tooltip
                                                id={`review-${review.uid}-stars-tip`}
                                                text={`${review.rating.rating/2} out of 5 stars`}
                                                offset={10}
                                                />

                                                <Tooltip
                                                id={`review-${review.uid}-first-listen-tip`}
                                                text={"Marked as a re-listen"}
                                                offset={12}
                                                />

                                                <Tooltip
                                                id={`review-${review.uid}-private-tip`}
                                                text={review.private_locked ? review.private ? `Kept private after validation` : `Automatically public after validation` : `This review is private`}
                                                offset={12}
                                                />

                                                
                                                <p className={`grey ${(isTablet || Boolean(review.rating.rating) || review.rating.first_listen === false) ? "margin-top-12" : ""}`}>{(!review.rating.hasOwnProperty("show_listened_at") || review.rating.show_listened_at) ? "Listened" : "Reviewed"} on {moment((!review.rating.hasOwnProperty("show_listened_at") || review.rating.show_listened_at) ? review.rating.listened_at : review.rating.created_at).format("DD MMM YYYY")}</p>
                                            </div>
                                            
                                            {isTablet &&
                                                <Link className={`margin-left-16 ${isSuggested ? styles.disabledLink : ""}`} to={href}>
                                                    {(review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") &&
                                                        <ProfilePicture
                                                        clickable
                                                        size={112}
                                                        profilePicture={contentImage}
                                                        altText={contentTitle}
                                                        />
                                                    }

                                                    {!(review.rating.content.type === "artist" || review.rating.content.type === "suggested_artist") &&
                                                        <AlbumCover
                                                        clickable
                                                        size={112}
                                                        albumCover={contentImage}
                                                        altText={contentTitle}
                                                        />
                                                    }
                                                </Link>
                                            }
                                        </div>

                                        {review.title !== "" && !isTablet &&
                                            <h4 className="black margin-top-16">{review.title}</h4>
                                        }
                                        {review.title !== "" && isTablet &&
                                            <h4 className="black margin-top-32" style={{ fontSize: 18 }}>{review.title}</h4>
                                        }

                                        <ParseUserText
                                        className={`${showTranslation && translatedReview ? "highDarkGrey" :  "textColor"} ${review.title === "" ? isTablet ? "margin-top-32" : "margin-top-16" : "margin-top-12"} margin-right-16`}
                                        userTextClassName="blue hover-underline"
                                        style={{ fontSize: 16, lineHeight: 1.5 }}
                                        text={review.description}
                                        appendId={"Review-description"}
                                        />
                                        
                                        {review.description !== "" && review.detected_language && primaryLanguage && review.detected_language !== primaryLanguage &&
                                            <div className="flex-row margin-top-16">
                                                <div 
                                                className={`flex-row ${styles.translateBtn}`}
                                                onClick={() => {
                                                    if (translatedReview) {
                                                        setShowTranslation(!showTranslation)
                                                    }
                                                    else if (!translatedReview && translateReviewStatus !== status.BEGIN) {
                                                        setShowTranslation(true)
                                                        dispatch(
                                                            translateReview(
                                                                reviewUid, 
                                                                review.description, 
                                                                review.detected_language, 
                                                                primaryLanguage
                                                            )
                                                        )
                                                    }
                                                }}
                                                >
                                                    <div className="flex-center margin-right-8" style={{ width: 16, height: 20 }}>
                                                        {translateReviewStatus === status.BEGIN &&
                                                            <Icon icon="loading" className="icon-small black" />
                                                        }
                                                        
                                                        {translateReviewStatus !== status.BEGIN &&
                                                            <Icon icon="language" color={"var(--blue)"} size={20} />
                                                        }
                                                    </div>
                                                    
                                                    <h6 className="blue">
                                                        {showTranslation && translatedReview ? `Translated From ${reviewLangDisplay} To ${primaryLangDisplay}` : "Translate This Review"}
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        
                                        {review.description !== "" && showTranslation && translatedReview &&
                                            <ParseUserText
                                            className={`textColor margin-top-16 margin-right-16`}
                                            userTextClassName="blue hover-underline"
                                            style={{ fontSize: 16, lineHeight: 1.5 }}
                                            text={translatedReview}
                                            appendId={"Review-translation-description"}
                                            />
                                        }

                                        <StickerSection 
                                        reviewUid={review.uid}
                                        appendKey={`review-${review.uid}`}
                                        />

                                        <AdComponent
                                        aboveTheFold
                                        className="margin-top-48"
                                        type="large"
                                        adChannel={`Review/Primary`}
                                        />
                                    </div>
                                </div>

                                {!isSmallScreen &&
                                    <ReviewCommentSection
                                    review={review}
                                    comments={comments}
                                    />
                                }
                            </div>

                            <div className="section-1 margin-top-32">
                                <StickyScrolling enabled={!isSmallScreen}>
                                    {!isTablet &&
                                        <div className="padding-top-32" />
                                    }

                                    {!isLoggedIn &&
                                        <SignUpAd type="review" username={review.creator.username} className="margin-bottom-32" />
                                    }


                                    <OptionsMenu
                                    options={[
                                        {
                                            text: likeObj ? "Liked review" : "Like review",
                                            onPress: () => {
                                                if (isLoggedIn) {
                                                    if (likeObj) {
                                                        dispatch(
                                                            deleteLike("review", review.uid, likeObj.uid, true)
                                                        )
                                                    } else {
                                                        track("Liked content", { type: "review", referrer: "Review" })

                                                        dispatch(
                                                            likeContent("review", review.uid, true)
                                                        )
                                                    }
                                                } else {
                                                    openPopup("SignUp")
                                                }
                                            },
                                            icon: likeObj ? (deleteLikeStatus === status.BEGIN && deleteLikeContentUid === review.uid) ? "loading" : "favorite" : "favorite-border",
                                            iconColor: likeObj ? (deleteLikeStatus === status.BEGIN && deleteLikeContentUid === review.uid) ? "var(--black)" : "var(--red)" : "var(--black)",
                                        },
                                        {
                                            text: `${checkNull(review.like_count)} ${checkNullNumber(review.like_count) !== 1 ? "Likes" : "Like"}`,
                                            onPress: () => openPopup("ListLikes", { contentType: "review", contentUid: review.uid }),
                                        },
                                        isMe && {
                                            text: "Edit or delete review",
                                            onPress: () => openPopup("UpdateReview", { review: review })
                                        },
                                        isMe && {
                                            text: `Manage who can comment`,
                                            onPress: () => {
                                                openPopup("ManageComments", { content: review, type: "review" })
                                            }
                                        },
                                        {
                                            text: isMe ? `Review this ${formattedContentType} again` : `Rate this ${formattedContentType}`,
                                            onPress: () => {
                                                if (!isLoggedIn) {
                                                    openPopup("SignUp")
                                                    return;
                                                }
                                                
                                                openPopup("CreateReview", { content: review.rating.content })
                                            }
                                        },
                                        isLoggedIn && {
                                            text: "Share on Musicboard",
                                            onPress: () => openPopup("SendContent", { type: "review", content: review }),
                                        },
                                        {
                                            onPress: () => {
                                                if (review.private || review.private_locked) {
                                                    return;
                                                }

                                                openPopup("ShareReview", { review: review, popupTitle: "Share Review" })
                                            },
                                            element: (
                                                <div 
                                                id={(review.private || review.private_locked) ? `review-${review.uid}-disabled-share-tip` : null}
                                                >
                                                    <div className={(review.private || review.private_locked) ? styles.disabledShare : ""}>
                                                        <ShareElement 
                                                        type="review" 
                                                        title={`Review of ${contentType === "artist" ? contentTitle : `${contentTitle} by ${review.rating.content.artist.name}`}`} 
                                                        image={contentImage} 
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        },
                                    ]}
                                    />

                                    <Tooltip
                                    id={`review-${review.uid}-disabled-share-tip`}
                                    text={`Make this review public to share`}
                                    offset={10}
                                    />

                                    {filteredUserReviews.length > 0 &&
                                        <Heading
                                        className="margin-top-32"
                                        text={`More reviews by ${review.creator.username}`}
                                        redirect={`/${review.creator.username}/reviews`}
                                        />
                                    }
                                    <div className="section-block">
                                        {filteredUserReviews.map((item, index) => (
                                            <ReviewItem
                                            review={item}
                                            isFirst={index === 0}
                                            isLast={index === filteredUserReviews.length-1}
                                            isSmall
                                            key={`review-${review.uid}-userreviews-${item.uid}`}
                                            />
                                        ))}
                                    </div>

                                    {filteredContentReviews.length > 0 &&
                                        <Heading
                                        className="margin-top-32"
                                        text={`More reviews of ${contentTitle}`}
                                        redirect={`${href}reviews?order_by=helpful`}
                                        />
                                    }
                                    
                                    <div className="section-block">
                                        {filteredContentReviews.map((item, index) => (
                                            <ReviewItem
                                            review={item}
                                            isFirst={index === 0}
                                            isLast={index === filteredContentReviews.length-1}
                                            isSmall
                                            key={`review-${review.uid}-contentreviews-${item.uid}`}
                                            />
                                        ))}
                                    </div>

                                    {isModerator &&
                                    <>
                                        <Heading
                                        text="Moderator"
                                        className="margin-top-32"
                                        />

                                        <div className='section-block padding-around'>
                                            <h5 className='black'>ID: {review.id}</h5>
                                            <p className='highDarkGrey margin-top-8'>UID: {review.uid}</p>

                                            <a 
                                            href={`https://api.musicboard.app/admin/reviews/review/${review.id}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            >
                                                <Button
                                                className='margin-top-16'
                                                type="secondary"
                                                text="Open in Admin"
                                                height={41}
                                                textStyle={{ fontSize: 14 }}
                                                />
                                            </a>
                                        </div>
                                    </>
                                    }

                                    {isSmallScreen &&
                                        <ReviewCommentSection
                                        review={review}
                                        comments={comments}
                                        />
                                    }

                                    <AdComponent
                                    className="margin-top-32"
                                    type="small"
                                    adChannel={`Review/Small`}
                                    />

                                    <MiniFooter className={"margin-top-24"} />
                                </StickyScrolling>
                            </div> 
                        </div>
                    </div>

                    <div style={{ height: 64 }} />
                </div>
            </div>
        </div>
    )
}