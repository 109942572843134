import styles from './listfilterbar.module.css'

import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import Icon from '../../../../components/Icon'
import { useHref } from '../../../../hooks/href'
import { useSelector } from 'react-redux'
import ListFilter from './components/ListFilter'
import { useMediaQuery } from 'react-responsive'
import Tooltip from '../../../../components/Tooltip'
import { useTheme } from '../../../../hooks/theme'

export default function ListFilterBar({ 
    className, 
    style,
    view,
    orderBy,
    contentType,
    genre,
    release_year,
    forceTablet,
}) {
    //General
    const { getHref } = useHref()
    const match = useRouteMatch()
    const { mode } = useTheme()

    //Auth
    const is_pro = useSelector(state => state.me.is_pro)

    //Responsive
    const _isTablet = useMediaQuery({ maxWidth: 768 })
    const isTablet = forceTablet || _isTablet

    //Data
    const genres = useSelector(state => state.genres.genres)

    return (
        <div className={`flex-row section-block ${className}`} style={{ alignItems: isTablet ? "flex-start" : "center", ...style }}>
            <div className={isTablet ? "" : "flex-row"} style={{ padding: isTablet ? 8 : 4, marginBottom: 2 }}>
                <ListFilter
                title="Sort By"
                parameter="order_by"
                
                filters={[
                    {
                        title: "Numbered",
                        ordering: [
                            {
                                value: null,
                                label: "List Order"
                            },
                            {
                                value: "-priority",
                                label: "Reverse Order"
                            },
                        ]
                    },
                    {
                        title: `Community Rating`,
                        ordering: [
                            {
                                value: "-average_rating",
                                label: `Highest First`
                            },
                            {
                                value: "average_rating",
                                label: `Lowest First`
                            },
                        ]
                    },
                    {
                        title: "Alphabetically",
                        ordering: [
                            {
                                value: "content_title",
                                label: "A-Z"
                            },
                        ]
                    },
                    {
                        title: "Popularity",
                        ordering: [
                            {
                                value: "-ratings_count",
                                label: "Music Popularity"
                            },
                        ]
                    },
                    {
                        title: "Time Posted",
                        ordering: [
                            {
                                value: "-created_at",
                                label: "Latest First"
                            },
                            {
                                value: "created_at",
                                label: "Oldest First"
                            },
                        ]
                    },
                ]}
                />
                
                <ListFilter
                title="Type"
                parameter="content_type"
                showInternalLabel
                filters={[
                    {
                        title: "Type",
                        ordering: [
                            {
                                value: null,
                                label: "All Types",
                                altLabel: "All",
                            },
                            {
                                value: "album",
                                label: "Albums"
                            },
                            {
                                value: "artist",
                                label: "Artists"
                            },
                            {
                                value: "track",
                                label: "Tracks"
                            },
                        ]
                    },
                ]}
                />

                <div style={{ position: 'relative' }}>
                    <ListFilter
                    title="Genre"
                    parameter="genre"
                    showInternalLabel
                    // proRequired
                    filters={[
                        {
                            title: "Genre",
                            ordering: [
                                {
                                    value: null,
                                    label: "All Genres",
                                    altLabel: "All",
                                },
                                ...genres.map((genre) => ({
                                    value: genre.id,
                                    label: genre.name,
                                }))
                            ]
                        },
                    ]}
                    />
                    
                    <div 
                    id={`list-genre-tip`}
                    className={`${styles.overlay} ${(contentType !== "album" && contentType !== "track") ? styles.active : ""}`} 
                    />

                    <Tooltip
                    id={`list-genre-tip`}
                    text="Select a type to filter on genres."
                    offset={2}
                    />
                </div>
                
                <div style={{ position: 'relative' }}>
                    <ListFilter
                    title="Release Year"
                    parameter="release_year"
                    showInternalLabel
                    // proRequired
                    filters={[
                        {
                            title: "All",
                            ordering: [
                                {
                                    value: null,
                                    label: "Any Year",
                                    altLabel: "All",
                                },
                            ]
                        },
                        {
                            title: "2020s",
                            ordering: [
                                {
                                    value: "2025",
                                    label: "2025"
                                },
                                {
                                    value: "2024",
                                    label: "2024"
                                },
                                {
                                    value: "2023",
                                    label: "2023"
                                },
                                {
                                    value: "2022",
                                    label: "2022"
                                },
                                {
                                    value: "2021",
                                    label: "2021"
                                },
                                {
                                    value: "2020",
                                    label: "2020"
                                },
                            ]
                        },
                        {
                            title: "2010s",
                            ordering: [
                                {
                                    value: "2019",
                                    label: "2019"
                                },
                                {
                                    value: "2018",
                                    label: "2018"
                                },
                                {
                                    value: "2017",
                                    label: "2017"
                                },
                                {
                                    value: "2016",
                                    label: "2016"
                                },
                                {
                                    value: "2015",
                                    label: "2015"
                                },
                                {
                                    value: "2014",
                                    label: "2014"
                                },
                                {
                                    value: "2013",
                                    label: "2013"
                                },
                                {
                                    value: "2012",
                                    label: "2012"
                                },
                                {
                                    value: "2011",
                                    label: "2011"
                                },
                                {
                                    value: "2010",
                                    label: "2010"
                                },
                            ]
                        },
                        {
                            title: "2000s",
                            ordering: [
                                {
                                    value: "2009",
                                    label: "2009"
                                },
                                {
                                    value: "2008",
                                    label: "2008"
                                },
                                {
                                    value: "2007",
                                    label: "2007"
                                },
                                {
                                    value: "2006",
                                    label: "2006"
                                },
                                {
                                    value: "2005",
                                    label: "2005"
                                },
                                {
                                    value: "2004",
                                    label: "2004"
                                },
                                {
                                    value: "2003",
                                    label: "2003"
                                },
                                {
                                    value: "2002",
                                    label: "2002"
                                },
                                {
                                    value: "2001",
                                    label: "2001"
                                },
                                {
                                    value: "2000",
                                    label: "2000"
                                },
                            ]
                        },
                        {
                            title: "Decades",
                            ordering: [
                                {
                                    value: "2020s",
                                    label: "2020s"
                                },
                                {
                                    value: "2010s",
                                    label: "2010s"
                                },
                                {
                                    value: "2000s",
                                    label: "2000s"
                                },
                                {
                                    value: "1990s",
                                    label: "1990s"
                                },
                                {
                                    value: "1980s",
                                    label: "1980s"
                                },
                                {
                                    value: "1970s",
                                    label: "1970s"
                                },
                                {
                                    value: "1960s",
                                    label: "1960s"
                                },
                                {
                                    value: "1950s",
                                    label: "1950s"
                                },
                                {
                                    value: "1940s",
                                    label: "1940s"
                                },
                                {
                                    value: "1930s",
                                    label: "1930s"
                                },
                                {
                                    value: "1920s",
                                    label: "1920s"
                                },
                                {
                                    value: "1910s",
                                    label: "1910s"
                                },
                                {
                                    value: "1900s",
                                    label: "1900s"
                                },
                            ]
                        },
                    ]}
                    />
                    
                    <div 
                    id={`list-year-tip`}
                    className={`${styles.overlay} ${(contentType !== "album" && contentType !== "track") ? styles.active : ""}`} 
                    />

                    <Tooltip
                    id={`list-year-tip`}
                    text="Select a type to filter on years."
                    offset={2}
                    />
                </div>
            </div>

            <div className="col-1" />

            <div className="flex-row" style={{ padding: 12 }}>
                <Link to={`${match.url}${getHref(null, "view", true)}`} className={`cursor`} style={{height: 24}}>
                    <Icon icon="view-comfy" className={`${styles.viewIcon} ${view !== "detailed" ? styles.active : ""}`} />
                </Link>

                <Link to={`${match.url}${getHref("detailed", "view", true)}`} className={`cursor margin-left-8`} style={{height: 24}}>
                    <Icon icon="view-headline" className={`${styles.viewIcon} ${view === "detailed" ? styles.active : ""}`} />
                </Link>
            </div>
        </div>
    )
}
