import styles from './home.module.css'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSelector, useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import appStoreBadge from '../../assets/images/app-store-badge.png'
import googlePlayBadge from '../../assets/images/google-play-badge.png'
import webVideo from '../../assets/videos/web_preview_v2.mp4'

import { appStoreUrl, feedbackUrl, googlePlayUrl, ogImageUrl, twitterImageUrl } from '../../config'
import { status, types } from '../../constants/action-types'

import { 
    getDiscover, 
    getNewReleases, 
    getFriendsReviews, 
    getFeaturedSections, 
    getFriendsLists, 
    getActiveUsers, 
    getFeaturedReviews,
    getTimelineReviews,
    paginationForTimeline,
    getTimelineLists,
    getSuggestedUsers
} from '../../actions/home-actions'
import { getRecommendedUsers } from '../../actions/users-actions'
import { paginationNext } from '../../actions/core-actions'
import { fetchAlbum, getPopularContent } from '../../actions/content-actions'

import { createOrganizationSchema, createSearchboxSchema } from '../../data/structured-data'

import { useTheme } from '../../hooks/theme'
import { useAlert } from '../../hooks/alert/alert'
import { usePopup } from '../../hooks/popup/popup'
import { usePlayVideo } from '../../hooks/play-video/play-video'

import ListItemLoader from '../../loaders/ListItemLoader'
import ReviewItemLoader from '../../loaders/ReviewItemLoader'

import Footer from '../../shared/Footer'
import DefaultHelmet from '../../shared/DefaultHelmet'
import StructuredData from '../../shared/StructuredData'

import { createABCTest } from '../../utils/abTesting'
import { filterAndFill } from '../../utils/format-array'

import Icon from '../../components/Icon'
import Button from '../../components/Button'
import Heading from '../../components/Heading'
import UserItem from '../../components/UserItem'
import ListItem from '../../components/ListItem'
import ThreadItem from '../../components/ThreadItem'
import ReviewItem from '../../components/ReviewItem'
import MiniFooter from '../../components/MiniFooter'
import AdComponent from '../../components/AdComponent'
import BigBackendItem from '../../components/BigBackendItem'
import StickyScrolling from '../../components/StickyScrolling'

import ProAd from './components/ProAd'
import NewsAd from './components/NewsAd'
import HomeBox from './components/HomeBox'
import GetStarted from './components/GetStarted'
import DiscoverItem from './components/DiscoverItem'
import ComposeReview from './components/ComposeReview'
import AnnouncementAd from './components/AnnouncementAd'

export default function Home() {
    //General
    const { openAlert } = useAlert()
    const { openVideo } = usePlayVideo()
    const { openPopup } = usePopup()
    const { colors } = useTheme()
    const dispatch = useDispatch()
    const onMobile = /Mobi/.test(navigator.userAgent) || (navigator.userAgent.match(/Safari/i) && !navigator.userAgent.match(/Chrome/i))
    const location = useLocation()

    //Responsive
    const isBigScreen = useMediaQuery({ maxWidth: 1230 })
    const isMidBigScreen = useMediaQuery({ maxWidth: 1180 })
    const isMidScreen = useMediaQuery({ maxWidth: 1100 })
    const isSmallMidScreen = useMediaQuery({ maxWidth: 960 })
    const isSmallScreen = useMediaQuery({ maxWidth: 960 })
    const isTablet = useMediaQuery({ maxWidth: 768 })
    const isMobile = useMediaQuery({ maxWidth: 630 })
    const isMobileSmall = useMediaQuery({ maxWidth: 510 })
    const isMinimumLarge = useMediaQuery({ maxWidth: 410 })
    const isMinimum = useMediaQuery({ maxWidth: 389 })

    //Data
    const lastCreatedSuggestedAlbum = useSelector(state => state.contributions.lastCreatedSuggestedAlbum)
    const lastCreatedSuggestedArtist = useSelector(state => state.contributions.lastCreatedSuggestedArtist)

    //Show a reset password alert from params
    const resetPasswordParam = new URLSearchParams(location.search).get("reset-password")
    useEffect(() => {
        if (resetPasswordParam === "success") {
            setTimeout(() => {
                openAlert({
                    style: "green",
                    p: "Your password has been reset."
                })
            }, 1200)
        } else if (resetPasswordParam === "error") {
            setTimeout(() => {
                openAlert({
                    style: "red",
                    timer: 8000,
                    p: "Your reset password link was not valid, please try again or contact us if the issue persists."
                })
            }, 1200)
        }
    }, [])

    //Show a reset password alert from params
    const deleteAccountParam = new URLSearchParams(location.search).get("deleted")
    useEffect(() => {
        if (deleteAccountParam === "true") {
            setTimeout(() => {
                openAlert({
                    p: "Your account has been permanently deleted.",
                    style: "green",
                    timer: 5000
                })
            }, 500)
        }
    }, [])
    
    //Show the new release popup based on params
    const newReleaseParam = new URLSearchParams(location.search).get("new")
    useEffect(() => {
        if (newReleaseParam === "true") {
            setTimeout(()=>{
                openPopup("NewVersion")
            }, 300)
        }
    }, [])

    //Show a verify email alert from params
    const verifyEmailParam = new URLSearchParams(location.search).get("verify-email")
    useEffect(() => {
        if (verifyEmailParam === "success") {
            setTimeout(() => {
                openAlert({
                    style: "green",
                    p: "Your email is now verified."
                })
            }, 1200)
        } else if (verifyEmailParam === "error") {
            setTimeout(() => {
                openAlert({
                    style: "red",
                    timer: 8000,
                    p: "Your verification link was not valid, please try again or contact us if the issue persists."
                })
            }, 1200)
        }
    }, [])

    //Redirect to App Store or Google Play based on param
    const redirectDownloadParam = new URLSearchParams(location.search).get("redirect-download")
    useEffect(() => {
        if (redirectDownloadParam === "true") {
            const onIOS = window.navigator.userAgent.includes("iPhone") || window.navigator.userAgent.includes("iPad");
            const onANDROID = window.navigator.userAgent.includes("Android");

            document.location.href = "musicboard://"

            setTimeout(() => {
                if (onIOS) {
                    document.location.href = appStoreUrl;
                } else if (onANDROID) {
                    document.location.href = googlePlayUrl;
                } else {
                    openPopup("ExtraInformation")
                }
            }, 1000)
        }
    }, [])

    //Created album alert and popup
    const createdAlbumParam = new URLSearchParams(location.search).get("created-album")
    useEffect(() => {
        if (createdAlbumParam === "true" && lastCreatedSuggestedAlbum) {
            openAlert({
                p: `Suggested "${lastCreatedSuggestedAlbum.title}"!`,
                style: "green",
                timer: 6000
            })

            setTimeout(() => {
                openPopup("BasicQuestion", { 
                    popupTitle: "Rate or Review?",
                    title: "Would you like to rate/review this album immediately?", 
                    texts: [
                        `You can rate and review new music right after adding it to our database. We will keep the review private on your profile until the musical information has been validated by moderators, after which we'll automatically publicize it if you'd like.`,
                        `Navigate to "Contribute" then "My Contributions" in the top right of our website to check the status of your submission or rate it at another time.`
                    ], 
                    primaryBtnText: "Rate Album", 
                    secondaryBtnText: "Skip",
                    primaryBtnOnClicked: () => {
                        setTimeout(() => {
                            openPopup("CreateReview", { content: lastCreatedSuggestedAlbum })
                        }, 200)
                    }
                })
            }, 200)
        }
    }, [])

    //Created artist alert and popup
    const createdArtistParam = new URLSearchParams(location.search).get("created-artist")
    useEffect(() => {
        if (createdArtistParam === "true" && lastCreatedSuggestedArtist) {
            openAlert({
                p: `Suggested "${lastCreatedSuggestedArtist.name}"!`,
                style: "green",
                timer: 6000
            })

            setTimeout(() => {
                openPopup("BasicQuestion", { 
                    popupTitle: "Rate or Review?",
                    title: "Would you like to rate/review this artist immediately?", 
                    texts: [
                        `You can rate and review new music right after adding it to our database. We will keep the review private on your profile until the musical information has been validated by moderators, after which we'll automatically publicize it if you'd like.`,
                        `Navigate to "Contribute" then "My Contributions" in the top right of our website to check the status of your submission or rate it at another time.`
                    ], 
                    primaryBtnText: "Rate Album", 
                    secondaryBtnText: "Skip",
                    primaryBtnOnClicked: () => {
                        setTimeout(() => {
                            openPopup("CreateReview", { content: lastCreatedSuggestedArtist })
                        }, 200)
                    }
                })
            }, 200)
        }
    }, [])

    //Auth
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const firstOpen = useSelector(state => state.auth.firstOpen)
    const openedProVersion = useSelector(state => state.auth.openedProVersion)
    const healthStatus = useSelector(state => state.auth.healthCheck.status)
    const show_pro_ad = useSelector(state => state.auth.healthCheck.show_pro_ad)
    const show_announcement = useSelector(state => state.auth.healthCheck.show_announcement)
    const show_news_ad = useSelector(state => state.auth.healthCheck.show_news_ad)
	const is_pro = useSelector(state => state.me.is_pro)

    //Navigate based on Auth params
	const firstUpdate = useRef(true)
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

        if (healthStatus === status.SUCCESS) {
            //Here we go to the NewVersion screen
            if (
                !openedProVersion &&
                !firstOpen && 
                isLoggedIn
            ) {
                dispatch({
                    type: "OPENED_PRO_VERSION"
                })
                
                openPopup("NewVersion")
            }
            
            if (firstOpen) {
                dispatch({
                    type: "OPENED_APP"
                })
            }
        }

	}, [ healthStatus ])

    //Local data
    const homeReviewsIsFriends = useSelector(state => state.local.homeReviewsIsFriends)
    const homeListsIsFriends = useSelector(state => state.local.homeListsIsFriends)
    const homeAlbumsIsPopular = useSelector(state => state.local.homeAlbumsIsPopular)

    //Data
    const discover = useSelector(state => state.home.discover)
    const activeUsers = useSelector(state => state.home.activeUsers)
    const recommendedUsers = useSelector(state => state.users.recommendedUsers)
    
    const friendsReviews = useSelector(state => state.home.friendsReviews)
    const friendsLists = useSelector(state => state.home.friendsLists)

    const lists = useSelector(state => state.home.timelineLists)
    const reviews = useSelector(state => state.home.timelineReviews)
    const featuredSections = useSelector(state => state.home.featuredSections)
    const featuredReviews = useSelector(state => state.home.featuredReviews)
    const suggestedUsers = useSelector(state => state.home.suggestedUsers)
    const newReleases = useSelector(state => state.home.newReleases)
    const popularAlbums = useSelector(state => state.albums.popularAlbums)

    //State
    const [adCompleted, setAdCompleted] = useState(false)

    //Ad Completed Callback
    const setAdCompletedCallback = useCallback(() => {
        setAdCompleted(true)
    }, [])
    
    //Get data
    useEffect(() => {
        dispatch( 
            getDiscover() 
        )
    }, [])
    
    useEffect(() => {
        if (isLoggedIn) {
            dispatch(
                getRecommendedUsers()
            )
        } else {
            dispatch(
                getTimelineReviews()
            )

            dispatch(
                getTimelineLists()
            )

            //dispatch(
            //    getSuggestedUsers(24)
            //)
        }
    }, [ isLoggedIn ])
    
    useEffect(() => {
        if (isLoggedIn) {
            if (homeReviewsIsFriends) { 
                dispatch(
                    getFriendsReviews({})
                )
            } else {
                dispatch(
                    getTimelineReviews()
                )
            }
        }
    }, [ isLoggedIn, homeReviewsIsFriends ])
    
    useEffect(() => {
        if (isLoggedIn) {
            if (homeListsIsFriends) { 
                dispatch(
                    getFriendsLists()
                )
            } else {
                dispatch(
                    getTimelineLists()
                )
            }
        }
    }, [ isLoggedIn, homeListsIsFriends ])
    
    useEffect(() => {
        if (isLoggedIn) {
            if (homeAlbumsIsPopular) { 
                dispatch(
                    getPopularContent({
                        contentType: "album",
                        weekly: true,
                        type: types.GET_POPULAR_ALBUMS,
                    })
                )
            } else {
                dispatch(
                    getNewReleases()
                )
            }
        } else {
        }
    }, [ isLoggedIn, homeAlbumsIsPopular ])

    //Prepare data for render
    const activeReviews = homeReviewsIsFriends ? friendsReviews : reviews;
    const activeLists = homeListsIsFriends ? friendsLists : lists;
    const activeAlbums = homeAlbumsIsPopular ? popularAlbums : newReleases;

    return (
        <div >
            <DefaultHelmet
            title="Musicboard - Ratings, Reviews, and Lists. Explore New Music."
            description="Musicboard is the world's #1 source for music reviews, ratings, lists and information. Find reviews for the latest music and join a community of music lovers."
            canonical="https://musicboard.app"
            headingOne="Musicboard - Music Reviews, Ratings and Lists"
            headingTwo="The World's #1 Source for Music Reviews, Ratings, Lists and Information"
            />

            <StructuredData
            key="Home-Organization-Data"
            id="Home-Organization-Data"
            data={createOrganizationSchema()}
            />
            
            <StructuredData
            key="Home-Searchbox-Data"
            id="Home-Searchbox-Data"
            data={createSearchboxSchema()}
            />

            {!isLoggedIn &&
                <div style={{ position: 'relative', overflow: 'hidden', marginTop: -57, paddingTop: isMobile ? 57+20 : 57+44, paddingBottom: isMobile ? 56 : isSmallScreen ? 64 : 84 }}>
                    <div className={styles.headerBackground} />
                    <div className={styles.headerLeft} />
                    <div className={styles.headerRight} />

                    <div className="page-container flex-column margin-top-48">
                        <div>
                            <div className={`flex-column flex-center`}>
                                <div className={`flex-column flex-center`}>
                                    <h4 
                                    className="black text-xl" 
                                    style={{
                                        fontWeight: '700',
                                        fontSize: isMobileSmall ? 26 : isMobile ? 32 : isTablet ? 38 : 44,
                                        lineHeight: isMobile ? '40px' : isTablet ? '46px' : '52px',
                                        marginTop: -4,
                                        maxWidth: 900,
                                        textAlign: 'center'
                                    }}>
                                        Review your favorite albums.{isMinimum ? " " : <br/>}
                                        {/* Discuss your favorite artists.{isMinimum ? " " : <br/>} */}
                                        Catalog{isMinimumLarge ? "" : " all"} music you listen to.{isMinimum ? " " : <br/>}
                                        Share your passion for music.
                                    </h4>

                                    <p 
                                    style={{ 
                                        maxWidth: isMidScreen ? 700 : 664, 
                                        fontSize: isMobile ? 16 : isMidBigScreen ? 18 : 20, 
                                        lineHeight: isMobile ? '26px' : isMidBigScreen ? '30px' : '32px' 
                                    }} 
                                    className={`text-center highDarkGrey margin-top-32`}
                                    >
                                        Musicboard is a social platform that allows you to keep track of all the music you listen to and grow your passion for music with friends. Write reviews, rate albums, and compile lists in music's fastest growing community.
                                    </p>

                                    {!(onMobile && isMobile) &&
                                        <div className={`flex-column flex-center margin-top-40`}>
                                            <Button
                                            height={49}
                                            onClicked={() => openPopup("SignUp")}
                                            text="Join Musicboard for free!"
                                            className={"padding-left-24 padding-right-24"}
                                            textStyle={{ fontSize: 18 }}
                                            />

                                            <div className={`flex-row margin-top-40`}>
                                                <h5 className="grey">{isMobile ? "" : "For music lovers. "}Download the app now on</h5>

                                                <a href={appStoreUrl} target="_blank" rel="noopener noreferrer" style={{ transform: 'translateY(-1px)' }}>
                                                    <Icon className={`${styles.mobile} margin-left-12`} icon="apple" />
                                                </a>

                                                <a href={googlePlayUrl} target="_blank" rel="noopener noreferrer">
                                                    <Icon className={`${styles.mobile} margin-left-12`} icon="android" />
                                                </a>
                                            </div>
                                        </div>
                                    }
                                    
                                    {onMobile && isMobile &&
                                        <div className={`${isMidScreen ? "flex-column flex-center" : "flex-row"} margin-top-40`}>
                                            <div className={`flex-row`}>
                                                <a target={"_blank"} href={appStoreUrl} className={styles.badgeWrapper}>
                                                    <img src={appStoreBadge} height={51} alt="App Store download badge" />
                                                    <span className={styles.badgeTitle}>Download Musicboard on App Store</span>
                                                </a>

                                                <a target={"_blank"} href={googlePlayUrl} className={`${styles.badgeWrapper} margin-left-12`}>
                                                    <img src={googlePlayBadge} height={51} alt="Google Play download badge" />
                                                    <span className={styles.badgeTitle}>Download Musicboard on Google Play</span>
                                                </a>
                                            </div>

                                            <div className="flex-row margin-top-12">
                                                <Button
                                                height={43}
                                                type="secondary"
                                                onClicked={() => openPopup("SignUp")}
                                                text="Or sign up now on web"
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="page-container flex-column">
                {!isLoggedIn &&
                    <div>
                        <Heading
                        text={"Popular This Week"}
                        />

                        <div className={`section-block padding-around`}>
                            {popularAlbums && popularAlbums.data && popularAlbums.data.length > 0 &&
                                <div className={`grid-view ${isMobile ? "three" : isTablet ? "four" : isSmallScreen ? "five" : isMidScreen ? "six" : "seven"}`}>
                                    {popularAlbums && filterAndFill(popularAlbums.data, isMobile ? 6 : isTablet ? 8 : isSmallScreen ? 10 : isMidScreen ? 6 : 7).map((item, index) => (
                                        <div key={`home-friendslatestrating-${item ? item.uid : index}`}>
                                            {item &&
                                                <BigBackendItem
                                                showArtistName
                                                content={item}
                                                />
                                            }
                                        </div>
                                    ))}
                                </div>
                            }
                            {popularAlbums && popularAlbums.data && popularAlbums.data.length <= 0 && popularAlbums.status !== status.BEGIN &&
                                <p className="empty-text">
                                    {`Could not load popular albums.`}
                                </p>
                            }
                            {popularAlbums && popularAlbums.data && popularAlbums.data.length <= 0 && popularAlbums.status === status.BEGIN &&
                                <p className="empty-text flex-center">
                                    <Icon icon="loading" disableLoadingAnimation />
                                </p>
                            }
                        </div>

                        <Heading 
                        text="Musicboard Lets You..." 
                        className="margin-top-32"
                        />

                        <div className={`grid-view ${isSmallScreen ? "one" : "three"}`}>
                            <HomeBox
                            className="col-1"
                            redirect="/welcome"
                            text="Keep track of all the music you have listened to (or just start from the day you join)"
                            icon="headphones"
                            iconColor={colors.blue}
                            />

                            <HomeBox
                            redirect="/welcome?disc"
                            text="Write reviews and rate music to share your opinions with friends and our community"
                            //icon="people"
                            //iconColor={colors.blue}
                            icon="subject"
                            iconColor={colors.red}
                            />

                            <HomeBox
                            className="col-1"
                            redirect="/welcome?lists=true"
                            text="Collect music into lists and show off your favorite albums, rank an artist's discography, and more"
                            icon="list-number"
                            iconColor={isSmallScreen ? colors.blue : colors.green}
                            />

                            <HomeBox
                            redirect="/welcome?listen-later=true"
                            text="Save music you haven't listened to yet and stay up to date as new albums are being released"
                            icon="access-time"
                            iconColor={colors.green}
                            />

                            <HomeBox
                            redirect="/welcome?discover=true"
                            text="Browse our Top 250 and Most Popular sections to discover great music and new artists"
                            icon="star"
                            iconColor={colors.yellow}
                            />

                            {!isSmallScreen &&
                                <HomeBox
                                redirect="/welcome?pro=true"
                                text="Unlock in-depth statistics about your musical interests and more with Musicboard Pro"
                                icon="verified"
                                iconColor={colors.blue}
                                />
                            }
                        </div>
                    </div>
                }

                <AdComponent
                aboveTheFold={isLoggedIn}
                className="margin-top-32"
                type="full"
                onAdCompleted={setAdCompletedCallback}
                />

                <div className="section-handler">
                    {/* LEFT SIDE */}
                    <div className="section-2 section-margin">
                        {!isLoggedIn &&
                            <div>
                                <Heading
                                text="Trending Reviews"
                                className="margin-top-32"
                                />

                                <div className="section-block">
                                    {reviews && reviews.data && reviews.data.length > 0 && 
                                        reviews.data.filter((_, i) => i < 6).map((item, index) => (
                                            <ReviewItem
                                            review={item}
                                            isLast={index === reviews.data.filter((_, i) => i < 6).length-1}
                                            isFirst={index === 0}
                                            key={`home-featuredreviews-${item.uid}`}
                                            />
                                        ))
                                    }

                                    {reviews && reviews.data && reviews.data.length <= 0 && reviews.status !== status.BEGIN &&
                                        <p className="empty-text">
                                            {`Could not load featured reviews.`}
                                        </p>
                                    }

                                    {reviews && reviews.data && reviews.data.length <= 0 && reviews.status === status.BEGIN &&
                                        <div className="flex-column">
                                            {[1,2,3,4,5].map((_, i) => (
                                                <div style={{ width: '100%', borderTop: i !== 0 ? '1px solid var(--lightGrey)' : '' }}>
                                                    <ReviewItemLoader />
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>

                                <AdComponent
                                className="margin-top-32" 
                                type="large"
                                />
                            </div>
                        }

                        {isLoggedIn &&
                            <div>
                                {/*
                                <LiveRatings className="margin-top-32" />
                                */}

                                <ComposeReview className="margin-top-32" />

                                {isLoggedIn && isSmallScreen &&
                                    <>
                                        {discover?.data?.length > 0 && discover.data.map((item, index) => (
                                            <DiscoverItem 
                                            discoverData={item} 
                                            className={index === 0 ? "margin-top-32" : "margin-top-8"} 
                                            isMultiple={discover.data.filter((i) => i.description !== "").length > 1} 
                                            />
                                        ))}

                                        {isLoggedIn && show_announcement &&
                                            <AnnouncementAd className="margin-top-32" />
                                        }

                                        {isLoggedIn && show_news_ad &&
                                            <NewsAd className="margin-top-32" />
                                        }

                                        {isLoggedIn && show_pro_ad && !is_pro &&
                                            <ProAd className="margin-top-32" />
                                        }
                                    </>
                                }

                                {isSmallScreen && 
                                <>
                                    <Heading
                                    text={homeAlbumsIsPopular ? "Popular This Week" : "Hot New Releases"}
                                    className="margin-top-32"
                                    renderRightContent={
                                        <h6 className="grey" style={{ fontSize: 13, textAlign: 'right' }}>
                                            <span onClick={() => dispatch({ type: types.SET_HOME_ALBUMS_IS_POPULAR, payload: true })} className={`cursor ${styles.switch} ${homeAlbumsIsPopular ? styles.switchActive : ""}`}>POPULAR</span>
                                            {" / "}
                                            <span onClick={() => dispatch({ type: types.SET_HOME_ALBUMS_IS_POPULAR, payload: false })} className={`cursor ${styles.switch} ${!homeAlbumsIsPopular ? styles.switchActive : ""}`}>NEW</span>
                                        </h6>
                                    }
                                    />

                                    <div className={`section-block padding-around`}>
                                        {activeAlbums && activeAlbums.data && activeAlbums.data.length > 0 &&
                                            <div className={`grid-view ${isMobile ? "three" : isTablet ? "four" : "five"}`}>
                                                {activeAlbums && filterAndFill(activeAlbums.data, isMobile ? 6 : isTablet ? 8 : 10).map((item, index) => (
                                                    <div key={`home-friendslatestrating-${item ? item.uid : index}`}>
                                                        {item &&
                                                            <BigBackendItem
                                                            showArtistName
                                                            content={item}
                                                            />
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                        {activeAlbums && activeAlbums.data && activeAlbums.data.length <= 0 && activeAlbums.status !== status.BEGIN &&
                                            <p className="empty-text">
                                                {homeAlbumsIsPopular ? `Could not load popular albums.` : `Could not load new releases.`}
                                            </p>
                                        }
                                        {activeAlbums && activeAlbums.data && activeAlbums.data.length <= 0 && activeAlbums.status === status.BEGIN &&
                                            <div className="empty-text flex-center">
                                                <Icon icon="loading" disableLoadingAnimation />
                                            </div>
                                        }
                                    </div>
                                </>
                                }

                                <Heading
                                text={homeReviewsIsFriends ? "Reviews by Friends" : "Timeline"}
                                className="margin-top-32"
                                renderRightContent={
                                    <h6 className="grey" style={{ fontSize: 13, textAlign: 'right' }}>
                                        <span onClick={() => dispatch({ type: types.SET_HOME_REVIEWS_IS_FRIENDS, payload: true })} className={`cursor ${styles.switch} ${homeReviewsIsFriends ? styles.switchActive : ""}`}>FRIENDS</span>
                                        {" / "}
                                        <span onClick={() => dispatch({ type: types.SET_HOME_REVIEWS_IS_FRIENDS, payload: false })} className={`cursor ${styles.switch} ${!homeReviewsIsFriends ? styles.switchActive : ""}`}>POPULAR</span>
                                    </h6>
                                }
                                />

                                <div className="section-block">
                                    {activeReviews && activeReviews.data && activeReviews.data.length > 0 &&
                                        <InfiniteScroll
                                        initialLoad={false}
                                        threshold={200}
                                        loadMore={() => {
                                            if (isSmallScreen) {
                                                return;
                                            }

                                            if (activeReviews.next) {
                                                if (homeReviewsIsFriends) {
                                                    dispatch( 
                                                        paginationNext(activeReviews.next, types.GET_FRIENDS_REVIEWS) 
                                                    )
                                                } else {
                                                    dispatch(
                                                        paginationForTimeline(activeReviews.next)
                                                    )
                                                }
                                            }
                                        }}
                                        hasMore={isSmallScreen ? false : Boolean(activeReviews.next || (activeReviews.status === status.BEGIN && !activeReviews.reset))}
                                        loader={
                                            <div key={`home-reviews-load-more`} style={{ backgroundColor: 'var(--white)', borderTop: '1px solid var(--lightGrey)' }}>
                                                <ReviewItemLoader />
                                            </div>
                                        }
                                        >
                                            {activeReviews.data.map((item, index) => (
                                                item.type === "thread" ?
                                                    <ThreadItem
                                                    thread={item}
                                                    isLast={!isSmallScreen && index === activeReviews.data.length-1}
                                                    isFirst={index === 0}
                                                    key={`home-thread-${item.uid}`}
                                                    />
                                                :
                                                    <ReviewItem
                                                    review={item}
                                                    isLast={!isSmallScreen && index === activeReviews.data.length-1}
                                                    isFirst={index === 0}
                                                    key={`home-reviews-${homeReviewsIsFriends}-${item.uid}`}
                                                    />
                                            ))}
                                        </InfiniteScroll>
                                    }

                                    {isSmallScreen && activeReviews && activeReviews.data && activeReviews.data.length > 0 &&
                                        <Link to={homeReviewsIsFriends ? "/reviews/following" : "/reviews?order_by=trending"}>
                                            <h6 className={styles.moreBtn}>More reviews...</h6>
                                        </Link>
                                    }

                                    {activeReviews && activeReviews.data && activeReviews.data.length <= 0 && activeReviews.status !== status.BEGIN &&
                                        <div className="empty-text flex-center flex-column">
                                            <p>
                                                {homeReviewsIsFriends ? `Start by following some other members. You will then get a feed of their reviews here.` : `Could not load popular reviews.`}
                                            </p>

                                            {homeReviewsIsFriends &&
                                                <Link to={`/users/?order_by=suggested`} className="margin-top-16">
                                                    <Button
                                                    text="Find Users to Follow"
                                                    textStyle={{ fontSize: 15 }}
                                                    height={40}
                                                    />
                                                </Link>
                                            }
                                        </div>
                                    }

                                    {activeReviews && activeReviews.data && activeReviews.data.length <= 0 && activeReviews.status === status.BEGIN &&
                                        <div className="flex-column">
                                            {[1,2,3,4,5].map((_, i) => (
                                                <div key={`home-reviews-loader-${i}`} style={{ width: '100%', borderTop: i !== 0 ? '1px solid var(--lightGrey)' : '' }}>
                                                    <ReviewItemLoader />
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    
                    {/* RIGHT SIDE */}
                    <div className="section-1">
                        <StickyScrolling key={`StickyScrolling-loaded-${adCompleted}`} enabled={!isSmallScreen && isLoggedIn}>
                            <div className='padding-top-32' />

                            {isLoggedIn && !isSmallScreen &&
                                <div className="">
                                    {isLoggedIn && show_pro_ad && !is_pro &&
                                        <ProAd className={"margin-bottom-32"} />
                                    }
                                    
                                    {isLoggedIn && show_announcement &&
                                        <AnnouncementAd className={"margin-bottom-32"} />
                                    }

                                    {isLoggedIn && show_news_ad &&
                                        <NewsAd className={"margin-bottom-32"} />
                                    }

                                    {discover?.data?.length > 0 && discover.data.map((item, index) => (
                                        <DiscoverItem 
                                        discoverData={item} 
                                        className={index === discover.data.length-1 ? "margin-bottom-32" : "margin-bottom-8"} 
                                        isMultiple={discover.data.filter((i) => i.description !== "").length > 1} 
                                        />
                                    ))}
                                </div>
                            }

                            {isLoggedIn && !isSmallScreen &&
                            <>
                                <Heading
                                className={""}
                                text={homeAlbumsIsPopular ? "Popular This Week" : "Hot New Releases"}
                                renderRightContent={
                                    <h6 className="grey" style={{ fontSize: 13, textAlign: 'right' }}>
                                        <span onClick={() => dispatch({ type: types.SET_HOME_ALBUMS_IS_POPULAR, payload: false })} className={`cursor ${styles.switch} ${!homeAlbumsIsPopular ? styles.switchActive : ""}`}>NEW</span>
                                        {" / "}
                                        <span onClick={() => dispatch({ type: types.SET_HOME_ALBUMS_IS_POPULAR, payload: true })} className={`cursor ${styles.switch} ${homeAlbumsIsPopular ? styles.switchActive : ""}`}>POPULAR</span>
                                    </h6>
                                }
                                />

                                <div className={`section-block`}>
                                    {activeAlbums && activeAlbums.data && activeAlbums.data.length > 0 &&
                                        <div className={`padding-around grid-view ${isSmallScreen ? "three" : isMidScreen ? "two" : "three"}`}>
                                            {activeAlbums && filterAndFill(activeAlbums.data, isSmallScreen ? 6 : isMidScreen ? 4 : 6).map((item, index) => (
                                                <div key={`home-activealbum-${item ? item.id : index}`}>
                                                    {item &&
                                                        <BigBackendItem
                                                        showArtistName
                                                        content={item}
                                                        />
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    
                                    {activeAlbums && activeAlbums.data && activeAlbums.data.length > 0 &&
                                        <Link to={homeAlbumsIsPopular ? "/albums?order_by=week" : "/albums?order_by=new"}>
                                            <h6 className={styles.moreBtn} style={{ borderTop: '1px solid var(--lightGrey)' }}>View more...</h6>
                                        </Link>
                                    }

                                    {activeAlbums && activeAlbums.data && activeAlbums.data.length <= 0 && activeAlbums.status !== status.BEGIN &&
                                        <p className="padding-around empty-text">
                                            {homeAlbumsIsPopular ? `Could not load popular albums.` : `Could not load new releases.`}
                                        </p>
                                    }

                                    {activeAlbums && activeAlbums.data && activeAlbums.data.length <= 0 && activeAlbums.status === status.BEGIN &&
                                        <div className="padding-around empty-text flex-center">
                                            <Icon icon="loading" disableLoadingAnimation />
                                        </div>
                                    }
                                </div>

                                <AdComponent
                                className="margin-top-32" 
                                type="small"
                                />
                            </>
                            }
                            
                            {/* LISTS */}
                            {!isLoggedIn &&
                                <div>
                                    <Heading
                                    text="Trending Lists"
                                    />

                                    <div className="section-block">
                                        {lists && lists.data && lists.data.length > 0 &&
                                            lists.data.filter((_, i) => i < 4).map((item, index) => (
                                                <ListItem
                                                list={item}
                                                hideUser
                                                isFirst={index === 0}
                                                isLast={index === lists.data.filter((_, i) => i < 4).length-1}
                                                isSmall
                                                key={`home-featuredlists-${item.uid}`}
                                                />
                                            ))
                                        }

                                        {lists && lists.data && lists.data.length <= 0 && lists.status !== status.BEGIN &&
                                            <p className="empty-text">No featured lists loaded.</p>
                                        }

                                        {lists && lists.data && lists.data.length <= 0 && lists.status === status.BEGIN &&
                                            <div className="flex-column">
                                                {[1,2,3].map((_, i) => (
                                                    <div key={`home-featuredlists-loader-${i}`} style={{ width: '100%', borderTop: i !== 0 ? '1px solid var(--lightGrey)' : '' }}>
                                                        <ListItemLoader />
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                            {isLoggedIn &&
                                <div>
                                    <Heading
                                    text={homeListsIsFriends ? "Lists by Friends" : "Popular Lists"}
                                    className="padding-top-32"
                                    renderRightContent={
                                        <h6 className="grey" style={{ fontSize: 13, textAlign: 'right' }}>
                                            <span onClick={() => dispatch({ type: types.SET_HOME_LISTS_IS_FRIENDS, payload: true })} className={`cursor ${styles.switch} ${homeListsIsFriends ? styles.switchActive : ""}`}>FRIENDS</span>
                                            {" / "}
                                            <span onClick={() => dispatch({ type: types.SET_HOME_LISTS_IS_FRIENDS, payload: false })} className={`cursor ${styles.switch} ${!homeListsIsFriends ? styles.switchActive : ""}`}>POPULAR</span>
                                        </h6>
                                    }
                                    />

                                    <div className="section-block">
                                        {activeLists && activeLists.data && activeLists.data.length > 0 &&
                                            activeLists.data.filter((_, i) => i < 4).map((item, index) => (
                                                <ListItem
                                                list={item} 
                                                isFirst={index === 0}
                                                isSmall
                                                key={`home-lists-${item.uid}`}
                                                />
                                            ))
                                        }

                                        {activeLists && activeLists.data && activeLists.data.length > 0 &&
                                            <Link to={homeListsIsFriends ? "/lists/following" : "/lists?order_by=trending"}>
                                                <h6 className={styles.moreBtn}>More lists...</h6>
                                            </Link>
                                        }

                                        {activeLists && activeLists.data && activeLists.data.length <= 0 && activeLists.status !== status.BEGIN &&
                                            <div className="empty-text flex-center flex-column">
                                                <p>
                                                    {homeListsIsFriends ? `Start by following some other members. You will then get a feed of their lists here.` : `Could not load popular lists.`}
                                                </p>

                                                {homeListsIsFriends &&
                                                    <Link to={`/users/?order_by=suggested`} className="margin-top-16">
                                                        <Button
                                                        text="Find Users to Follow"
                                                        textStyle={{ fontSize: 15 }}
                                                        height={40}
                                                        />
                                                    </Link>
                                                }
                                            </div>
                                        }

                                        {activeLists && activeLists.data && activeLists.data.length <= 0 && activeLists.status === status.BEGIN &&
                                            <div className="flex-column">
                                                {[1,2,3,4].map((_, i) => (
                                                    <div key={`home-lists-loader-${i}`} style={{ width: '100%', borderTop: i !== 0 ? '1px solid var(--lightGrey)' : '' }}>
                                                        <ListItemLoader />
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            
                            {/* USERS */}
                            {!isLoggedIn &&
                                <div>
                                    <Heading
                                    text="Popular Reviewers"
                                    className="padding-top-32"
                                    />

                                    <div className="section-block padding-around-8">
                                        {suggestedUsers && suggestedUsers.data && suggestedUsers.data.length > 0 &&
                                            suggestedUsers.data.filter((_, i) => i < 14).map((item, index) => (
                                                <UserItem
                                                user={item}
                                                key={`home-suggestedUsers-${item.uid}`}
                                                />
                                            ))
                                        }

                                        {suggestedUsers && suggestedUsers.data && suggestedUsers.data.length <= 0 && suggestedUsers.status !== status.BEGIN &&
                                            <p className="empty-text">No users loaded.</p>
                                        }

                                        {suggestedUsers && suggestedUsers.data && suggestedUsers.data.length <= 0 && suggestedUsers.status === status.BEGIN &&
                                            <div className="empty-text flex-center">
                                                <Icon icon="loading" />
                                            </div>
                                        }
                                    </div>

                                    <AdComponent
                                    className="margin-top-32" 
                                    type="small"
                                    />
                                </div>
                            }

                            {isLoggedIn &&
                                <div>
                                    <Heading
                                    text="Recommended Users"
                                    className="padding-top-32"
                                    />

                                    <div className="section-block padding-around-8">
                                        {recommendedUsers && recommendedUsers.data && recommendedUsers.data.length > 0 &&
                                            recommendedUsers.data.filter((_, i) => i < 10).map((item, index) => (
                                                <UserItem
                                                user={item}
                                                key={`home-user-${item.uid}`}
                                                />
                                            ))
                                        }

                                        {recommendedUsers && recommendedUsers.data && recommendedUsers.data.length <= 0 && recommendedUsers.status !== status.BEGIN &&
                                            <p className="empty-text">No users loaded.</p>
                                        }

                                        {recommendedUsers && recommendedUsers.data && recommendedUsers.data.length <= 0 && recommendedUsers.status === status.BEGIN &&
                                            <div className="empty-text flex-center">
                                                <Icon icon="loading" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                            {/* ENDING */}
                            {isLoggedIn &&
                                <MiniFooter />
                            }

                            {isLoggedIn &&
                                <a rel="noopener noreferrer" target="_blank" href={feedbackUrl}>
                                    <Button
                                    type="secondary"
                                    text="Leave Feedback"
                                    height={40}
                                    textStyle={{ fontSize: 14 }}
                                    className="margin-top-16"
                                    />
                                </a>
                            }

                            {isLoggedIn &&
                                <Button
                                type="secondary"
                                onClicked={() => window.scrollTo(0,0)}
                                text="Back to Top"
                                height={40}
                                textStyle={{ fontSize: 14 }}
                                className="margin-top-8 margin-bottom-32"
                                />
                            }
                            
                            {isLoggedIn &&
                                <AdComponent
                                className="margin-top-48" 
                                type="vertical"
                                />
                            }
                        </StickyScrolling>
                    </div>
                </div>

                {!isLoggedIn &&
                    <GetStarted className="margin-top-32" />
                }
                
                {isMobile &&
                    <div style={{ height: 48 }} />
                }

            </div>

            {!isLoggedIn &&
                <Footer />
            }
        </div>
    )
}
